import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";

//import Scss
import "./assets/scss/themes.scss";

import "./sass/main.scss";

import Route from "./Routes";
import { logoutUser } from "./store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { loadAnimation } from "lottie-web";
import { defineElement } from "lord-icon-element";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import SessionExpirationModal from "./Components/SessionExpirationModal";

// register lottie and define custom element
defineElement(loadAnimation);

function App() {
  const INACTIVITY_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds

  const [isActive, setIsActive] = useState(true);
  const timeoutRef = useRef(null);

  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const history = useHistory();

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsActive(false);
      // Handle inactivity (e.g., logout)
      handleLogout();
    }, INACTIVITY_TIME);
  }, [INACTIVITY_TIME]);

  console.log(location.pathname, "CURRENTT_PATHH");

  const handleLogout = async () => {
    // return navigate("/logout");

    if (location?.pathname === "/login" || isSessionExpired) return;

    setIsSessionExpired(true);
    dispatch(logoutUser());
  };

  useEffect(() => {
    const handleActivity = () => {
      if (!isActive) {
        setIsActive(true);
      }
      resetTimeout();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);

    resetTimeout();

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isActive, resetTimeout]);

  return (
    <React.Fragment>
      <SessionExpirationModal isExpired={isSessionExpired} />

      <Route />
    </React.Fragment>
  );
}

export default App;
