import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//redux
import * as Yup from "yup";
import DateField from "../../Components/Common/Fields/DateField";

import { handleValidDate } from "../../Utility/index.js";
import { useSearchParams, useNavigate } from "react-router-dom";

const statusOptions = [
  { label: "Rejected", value: "rejected" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Success", value: "successful" },
  { label: "Pending", value: "pending" },
  { label: "Fail", value: "fail" },
];

const CrmFilter = ({ show, onCloseClick }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: "",
      amount: "",
      date_joined: "",
      amount: "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter A Salutation"),
      first_name: Yup.string().notRequired("Please Enter A First Name"),
      last_name: Yup.string().notRequired("Please Enter A Last Name"),
      date_of_birth: Yup.date().notRequired("Please enter date of birth"),
      gender: Yup.string().notRequired(),
      language: Yup.string().notRequired("Please Enter A Language"),
    }),

    onSubmit: (client) => {},
  });

  const updateQueryParam = (value, name) => {
    // Create a new URLSearchParams object from the current searchParams
    const newSearchParams = new URLSearchParams(searchParams);

    // Delete existing parameters
    newSearchParams.delete("offset");
    newSearchParams.delete("limit");

    // Set the new parameter
    newSearchParams.set(name, value);

    // Update the URL with the new search parameters
    navigate({ search: newSearchParams.toString() });
    filterValidation.setFieldValue(`${name}`, value);
  };

  console.log(filterValidation.values, "FILTER_VALUESSA");

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Payment request filters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            filterValidation.handleSubmit();
            return false;
          }}
          onReset={() => {
            filterValidation.handleReset();

            navigate("/requests");
          }}
        >
          <OffcanvasBody>
            <div className="">
              <DateField
                md="12"
                value={filterValidation.values.date || ""}
                onChange={(e) => {
                  const date = handleValidDate(e);

                  updateQueryParam(date || "", "date_joined");
                }}
                validation={filterValidation}
                onBlur={filterValidation.handleBlur}
                label="Date"
                name="date"
              />
            </div>

            <div className="">
              <Label>Status</Label>
              <Select
                md="12"
                // onChange={filterValidation.handleChange}
                isClearable
                onChange={(e) => updateQueryParam(e?.value || "", "status")}
                onBlur={filterValidation.handleBlur}
                placeholder="Select a status..."
                name="status"
                optional={true}
                options={statusOptions}
                // value={filterValidation.values.status || ""}
                value={{
                  value: filterValidation.values.status,
                  label: filterValidation.values.status || "Select status",
                }}
              />
            </div>

            <div className="mb-4">
              <Label className="form-label">Amount</Label>
              <Input
                md="12"
                mb="mb-3"
                value={filterValidation.values.amount || ""}
                // onChange={filterValidation.handleChange}
                onChange={(e) => {
                  console.log(e.target.value, "E-TARGETTTT");

                  updateQueryParam(e?.target.value || "", "amount");
                }}
                onBlur={filterValidation.handleBlur}
                placeholder="Enter amount"
                name="amount"
              />
            </div>

            {/* 
            <TextField
              md="12"
              mb="mb-3"
              value={filterValidation.values.amount || ""}
              // onChange={filterValidation.handleChange}
              onChange={(e) => updateQueryParam(e?.value || "", "amount")}
              validation={filterValidation}
              onBlur={filterValidation.handleBlur}
              placeholder="Enter amount"
              label="amount"
              name="amount"
            /> */}
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
