import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_FEES, GET_FEES_SUCCESS } from "./actionType";

import { getFeesSuccess } from "./action";

import { getUser, getFees } from "../../helpers/backend_helper";

function* getFeesWorker({ payload }) {
  try {
    const response = yield call(getFees, payload);

    console.log(response, "RESSSSPONSE");

    yield put(getFeesSuccess(response));
  } catch (error) {
    // yield put(UsersApiResponseError(GET_USER, error));
  }
}

export function* watchGetFeesWorker() {
  yield takeEvery(GET_FEES, getFeesWorker);
}

function* BillingSaga() {
  yield all([fork(watchGetFeesWorker)]);
}

export default BillingSaga;
