import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import AllTransactions from "../../Paybill/AllTransactions";
import Widgets from "../../Paybill/Widgets";
import BillsItems from "../../Paybill/BillsItems";

const Paybill = () => {
  document.title = "Pay bills | SimplyPay";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pay Bills" pageTitle="Pay Bills" />
          <Row>
            <Widgets />
          </Row>

          <Row>
            <Card className="ribbon-box right overflow-hidden">
              <div className="dashboardaccount__savedcards">
                <div className="dashboardaccount__head">
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Quickly pay your bills</h4>
                    <div className="flex-shrink-0"></div>
                  </CardHeader>

                  <img src="/static/icons/bills.png" alt="" />
                </div>

                <BillsItems />
              </div>
            </Card>
          </Row>

          {/* <AllTransactions /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Paybill;
