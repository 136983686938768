import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SwiperCore, { Autoplay } from "swiper";

import ManagePasswordTab from "./Tabs/ManagePasswordTab";
import ManageProfileTab from "./Tabs/ManageProfileTab";
import SupportTab from "./Tabs/SupportTab";

import SettingsSkeleton from "../../Components/Skeletons/SettingsSkeleton";
import ManageWalletTab from "./Tabs/ManageWalletTab";
import { Status } from "./TransactionsCol";
import ManageSecurityTab from "./Tabs/ManageSecurityTab";
import UserInformation from "./UserInformation";

const SimplePage = () => {
  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.title = "Profile";

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <SettingsSkeleton>
            <div className="page-content">
              <Container fluid>
                <UserInformation />
              </Container>
            </div>
          </SettingsSkeleton>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SimplePage;
