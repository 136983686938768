import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";

import { Button, Form, FormFeedback, Input, Label, Alert } from "reactstrap";
// import Router from "next/router";
import AOS from "aos";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { APIClient } from "../../utils/api_helper";
import SimplyHead from "../../Components/Common/SimplyHead";

const api = new APIClient();

export default function Home() {
  const [passwordShown, setPasswordShown] = useState(false);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState(false);

  const { token, activation_key } = useParams();

  console.log(token, "TOKKEEEN");

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  const togglePasswordVisiblity = () => setPasswordShown(passwordShown ? false : true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      new_password1: "",

      new_password2: "",
    },

    validationSchema: Yup.object({
      new_password1: Yup.string().required("Please enter a new password"),
      new_password2: Yup.string().required("Please confirm your newpassword"),
    }),

    onSubmit: async (values) => {
      console.log("VHANGINNGGG PASSOWRD");
      setErrorMsg("");
      setSuccessMsg("");
      try {
        const data = await api.post(`/account/reset-password/${token}/${activation_key}/`, values);

        console.log(data, "NEW_METAA");

        // const access_token = data.access_token;

        setSuccessMsg(data.message);

        validation.resetForm();

        // localStorage.setItem("access_token", access_token);

        // setTimeout(() => {
        //   window.location.href = "/";
        // }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  document.title = "SimplyPay | reset password";

  return (
    <section className="login__section">
      <div className="login">
        <div className="login__container">
          <SimplyHead />

          <p className="login__smalltext">Enter your new password to reset your password</p>

          <Alert color="success">
            {/* <div>{errMsg} </div> */}
            Type your new password
          </Alert>

          <div className="login__formcontainer">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
              className="login__form"
            >
              <div className="login__group">
                <Label className="login__label" htmlFor="password">
                  New password
                </Label>
                <Input
                  name="new_password1"
                  value={validation.values.new_password1 || ""}
                  type={passwordShown ? "text" : "password"}
                  className="login__input"
                  placeholder="Enter new password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={validation.touched.new_password1 && validation.errors.new_password1 ? true : false}
                />
                {validation.touched.new_password1 && validation.errors.new_password1 ? (
                  <FormFeedback type="invalid">{validation.errors.new_password1}</FormFeedback>
                ) : null}
                {/* <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                >
                  <i
                    onClick={togglePasswordVisiblity}
                    className="ri-eye-fill align-middle"
                  ></i>
                </button> */}
              </div>
              <div className="login__group">
                <Label className="login__label" htmlFor="password">
                  Confirm new password
                </Label>
                <Input
                  name="new_password2"
                  value={validation.values.new_password2 || ""}
                  type={passwordShown ? "text" : "password"}
                  className="login__input"
                  placeholder="Confirm new password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={validation.touched.new_password2 && validation.errors.new_password2 ? true : false}
                />
                {validation.touched.new_password2 && validation.errors.new_password2 ? (
                  <FormFeedback type="invalid">{validation.errors.new_password2}</FormFeedback>
                ) : null}
                {/* <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                >
                  <i onClick={togglePasswordVisiblity} className="ri-eye-fill align-middle">
                     
                  </i>
                </button> */}
              </div>

              {/* <div className="login__remembermecontainer">
                <div className="login__rememberme">
                  <Input
                    // className="login"
                    type="checkbox"
                    value=""
                    id="auth-remember-check"
                  />
                  <Label className="login__label" htmlFor="auth-remember-check">
                    Remember password
                  </Label>
                </div>

              </div> */}

              <Button color="success" disabled={validation.isSubmitting} className="login__button" type="submit">
                {!validation.isSubmitting ? "Reset password" : "please wait"}
              </Button>
            </Form>

            <div className="login__remembermecontainer">
              <div className="login__rememberme">
                <Link to="/login" className="d-inline-block auth-logo">
                  Remember password ?, login
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-left" className="login__side">
          <img src="/static/loginside.png" alt="" />
        </div>
      </div>

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </section>
  );
}
