import { GET_FEES, GET_FEES_SUCCESS } from "./actionType";

const INIT_STATE = {
  usersList: null,
};

const Billings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEES_SUCCESS:
      return {
        ...state,
        getFeesSuccess: true,
        fees: action.payload,
        isFetchingFee: false,
      };
    case GET_FEES:
      return {
        ...state,
        fees: action.payload,
        isFetchingFee: true,
      };

    // case RESET_USER_FLAG:
    //   return {
    //     ...state,

    //     errors: false,
    //   };
    default:
      return { ...state };
  }
};

export default Billings;
