import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";

import { Button, Form, FormFeedback, Input, Label, Alert } from "reactstrap";
// import Router from "next/router";
import AOS from "aos";
import "aos/dist/aos.css";

import { APIClient } from "../../utils/api_helper";
import { Link } from "react-router-dom";
import SimplyHead from "../../Components/Common/SimplyHead";

const api = new APIClient();

export default function Home() {
  const [passwordShown, setPasswordShown] = useState(false);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const togglePasswordVisiblity = () => setPasswordShown(passwordShown ? false : true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please enter a email"),
    }),

    onSubmit: async (values) => {
      console.log("sisisi");
      setErrorMsg("");
      try {
        const data = await api.post("/account/forgot-password/", values);

        console.log(data, "NEW_METAA");

        // const access_token = data.access_token;

        setSuccessMsg("Please check your email to reset your password");

        // localStorage.setItem("access_token", access_token);

        // setTimeout(() => {
        //   // history.push("/");
        //   window.location.href = "/";
        // }, 2000);
        // return Router.replace("/").then(() => Router.reload());
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  document.title = "SimplyPay | forgot password";

  return (
    <section className="login__section">
      <div className="login">
        <div className="login__container">
          <SimplyHead />

          {/* <div className="text-center mt-2">
            <h5 className="login__head">Simply Pay</h5>
            <p className="login__smalltext">Enter your email to reset your account.</p>
          </div> */}
          <p className="login__smalltext">Enter your email to reset your account.</p>

          <div className="login__formcontainer">
            <Alert color="success">Type your email</Alert>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
              className="login__form"
            >
              <div className="login__group">
                <Label htmlFor="email" className="login__label">
                  Email
                </Label>
                <Input
                  name="email"
                  className="login__input"
                  placeholder="Enter email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={validation.touched.email && validation.errors.email ? true : false}
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
              </div>

              <Button color="success" disabled={validation.isSubmitting} className="login__button" type="submit">
                {!validation.isSubmitting ? "Reset password" : "Sending, please wait"}
              </Button>
            </Form>
          </div>

          <div className="login__rememberme">
            <Link to="/login" className="d-inline-block auth-logo">
              remember password ?
            </Link>
          </div>
        </div>

        <div data-aos="fade-left" className="login__side">
          <img src="/static/loginside.png" alt="" />
        </div>
      </div>

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </section>
  );
}
