import React from "react";
import ReactApexChart from "react-apexcharts";
import { CardHeader } from "reactstrap";
// import dynamic from "next/dynamic";

// const ReactApexChart = dynamic(() => import("react-apexcharts"), {
//   ssr: false,
// });

function getChartColorsArray(colors) {
  if (!colors) return;

  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default function DashedlineChart({ dataColors, spendingReportings }) {
  var DashedLineColors = getChartColorsArray(dataColors);

  const spendingReportingSums = spendingReportings?.stats;
  const spendingReportingsDays = spendingReportings?.days;
  const payoutReportingSums = spendingReportings?.payout;
  const paymentRequestsReportingSums = spendingReportings?.payment_requests;

  var series = [
    {
      name: "Funds",
      data: spendingReportingSums || [],
    },
    {
      name: "expenses",
      data: payoutReportingSums || [],
    },
    {
      name: "payment requests",
      data: paymentRequestsReportingSums || [],
    },
    // {
    //   name: "expenses",
    //   data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49],
    // },
  ];
  var options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: DashedLineColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 4, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    title: {
      text: "Last 7 days transactions overview",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    markers: {
      size: 0,

      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: spendingReportingsDays || [],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  return (
    <React.Fragment>
      {/* <CardHeader> <h5 className="card-title mb-0">Last 7 days transactions</h5> </CardHeader> */}
      <ReactApexChart options={options} series={series} type="line" height="380" className="apex-charts" />
    </React.Fragment>
  );
}
