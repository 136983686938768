import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";

import FeatherIcon from "feather-icons-react";

const Widgets = ({ data }) => {
  return (
    <React.Fragment>
      <Col md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex justify-content-between card-body bg-soft-success">
              <div>
                <p className="fw-medium text-muted mb-0">PAYIN TRANSACTIONS (last 7 days)</p>
                <h2 className="mt-4 ff-secondary btn-ghost-success">
                  + <span>&#8373;</span>
                  <span className="counter-value" data-target="97.66">
                    <CountUp start={0} end={data?.received_total} decimals={2} duration={2} />
                  </span>
                </h2>
                {/* <p className="mb-0 text-muted">
                  <span className="badge bg-light text-danger mb-0">
                    <i className="ri-arrow-down-line align-middle"></i> 3.96 %
                  </span>{" "}
                  vs. previous week
                </p> */}
              </div>
              <div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-info rounded-circle fs-2">
                    <FeatherIcon icon="activity" className="text-info" />
                  </span>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md={6}>
        <Card className="card-animate">
          <CardBody>
            {/* <div className="d-flex justify-content-between"> */}
            <div className="d-flex justify-content-between card-body bg-soft-danger">
              <div>
                <p className="fw-medium text-muted mb-0">PAYOUT TRANSACTIONS (last 7 days)</p>
                <h2 className="mt-4 ff-secondary">
                  <span className="counter-value btn-ghost-danger" data-target={data?.sent_total}>
                    - <span>&#8373;</span>
                    <CountUp start={0} end={data?.sent_total} decimals={2} duration={1} />
                  </span>
                </h2>
                {/* <p className="mb-0 text-muted">
                  <span className="badge bg-light text-success mb-0">
                    <i className="ri-arrow-up-line align-middle"></i> 7.05 %
                  </span>{" "}
                  vs. previous week
                </p> */}
              </div>
              <div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-info rounded-circle fs-2">
                    <FeatherIcon icon="external-link" className="text-info" />
                  </span>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
