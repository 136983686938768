import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../../utils/api_helper";
import TextField from "../../../Components/Common/Fields/TextField";
import { useDispatch } from "react-redux";
import { getCards } from "../../../store/actions";
import useSWR from "swr";
import usdtCoin from "../../../assets/images/svg/crypto-icons/usdt.svg";

import { topPerformers } from "../../../common/data";

const api = new APIClient();

const loanTypeFetcher = async (url) => {
  const data = await api.get(url);

  return data;
};

const loanImages = {
  personal: "/static/personalloan.png",
  health: "/static/healthloan.png",
  student: "/static/studentloan.png",
  business: "/static/businessloan.png",
};

const LoanModal = ({ openModal, setOpenModal, reference }) => {
  let { data: loanTypesList, isValidating } = useSWR(`/loan/types/`, loanTypeFetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });
  const [modal, setModal] = useState(false);

  console.log(loanTypesList, "LOANN__TYPEE_DATATA");

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding sssscard, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });
  const togglemodal = () => {
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <Modal
        id="topmodal"
        isOpen={openModal}
        toggle={() => {
          // setOpenModal(false);
          // topBordertoggle("1");
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            setOpenModal(false);
            // topBordertoggle("1");
          }}
        ></ModalHeader>
        <ModalBody className="">
          <Col xxl={4} lg={62}>
            <Card className="card-height-100">
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Select a aloan to apply</h4>
              </CardHeader>
              <CardBody className="p-0">
                <ul className="list-group list-group-flush border-dashed mb-0">
                  {loanTypesList?.data?.map((item, key) => (
                    <Link key={key} to={`/loan/apply/${item.name}`} data-bs-toggle="modal">
                      <li className="list-group-item d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={loanImages[item.name]} className="avatar-xs" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="fs-14 mb-1">{item?.name?.toUpperCase()} LOAN</h6>
                          <p className="text mb-0">interest {item.rate} % </p>
                        </div>
                        <div className="flex-shrink-0 text-end">
                          <Link to={`/loan/apply/${item.name}`} className="fw-medium  text-primary">
                            <button className="btn btn-primary w-100" type="submit">
                              <h6 className="fs-14 mb-1">Apply</h6>
                            </button>
                          </Link>
                          <p className={"fs-12 mb-0 text-success"}>max amount {item.max_amount} GHS</p>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
      </Modal>

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default LoanModal;
