import React from "react";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";

const Index = () => {
  const location = useLocation();

  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);

  return (
    <React.Fragment>
      {/* <Routes> */}
      {/* <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route path={route.path} component={route.component} key={idx} exact={true} />
            ))}
          </Routes>
          </NonAuthLayout>
        </Route> */}
      {/* <Route path={availableAuthRoutesPath}>
          <AuthProtected>
            <VerticalLayout>
            <Route>
              {authProtectedRoutes.map((route, idx) => (
                <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
              ))}
            </Route>
            </VerticalLayout>
          </AuthProtected>
        </Route>
      </Routes> */}
      {/* <Router> */}
      {/* 
      <Routes>
        <Route element={<AuthProtected />}>
          <Route element={<VerticalLayout />}>
            {authProtectedRoutes.map((route, idx) => {
              return <Route path={route.path} element={route.component} key={idx} exact={true} />;
            })}
          </Route>
        </Route>
      </Routes> */}

      {availablePublicRoutesPaths.includes(location?.pathname) ||
      location?.pathname?.split("-")[0] === "/reset" ? null : (
        <VerticalLayout>
          <Routes>
            <Route element={<AuthProtected />}>
              {authProtectedRoutes.map((route, idx) => {
                return <Route path={route.path} element={route.component} key={idx} exact={true} />;
              })}
            </Route>
          </Routes>
        </VerticalLayout>
      )}

      <NonAuthLayout>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.component} key={idx} exact={true} />
          ))}
        </Routes>
      </NonAuthLayout>

      {/* </Router> */}
    </React.Fragment>
  );
};

export default Index;
