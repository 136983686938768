import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/RequestsTableContainr";

import { FromCol, ToCol, DetailsCol, TransactionID, TypeCol, Status } from "./TransactionsCol";
import EmptyList from "../../Components/EmptyList";

const AllTransactions = () => {
  const dispatch = useDispatch();

  const transationList = [];

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (transation) => (
          <>
            <div className="avatar-xs">
              <div
                className={
                  "avatar-title bg-soft-" +
                  transation.row.original.iconClass +
                  " text-" +
                  transation.row.original.iconClass +
                  " rounded-circle fs-16"
                }
              >
                <i className={transation.row.original.icon}></i>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Type of bill",
        Cell: (transation) => (
          <>
            {transation.row.original.date} <small className="text-muted">{transation.row.original.time}</small>
          </>
        ),
      },
      {
        Header: "Amount",
        Cell: (transation) => (
          <>
            <div className="d-flex align-items-center">
              <img src={transation.row.original.image} alt="" className="avatar-xxs me-2" />
              {transation.row.original.currency}
            </div>
          </>
        ),
      },

      {
        Header: "Details",
        accessor: "details",
        filterable: false,
        Cell: (cellProps) => {
          return <DetailsCol {...cellProps} />;
        },
      },
      {
        Header: "Source",
        accessor: "id",
        filterable: false,
        Cell: (cellProps) => {
          return <TransactionID {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      {/* <Row className="align-items-center mb-4 g-3">
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-2">
            <Link to="#" data-bs-toggle="modal" className="btn btn-info">
              Pay Bill
            </Link>
          </div>
        </div>
      </Row> */}

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">All Bills</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <div className="search-box">
                  <input type="text" className="form-control search" placeholder="Search for bills..." />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <button className="btn btn-success">
                  <i className="ri-equalizer-line align-bottom me-1"></i>
                  Filters
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            columns={columns}
            data={transationList || []}
            isGlobalFilter={false}
            isAddUserList={false}
            customPageSize={8}
            className="custom-header-css"
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
            thClass="table-light text-muted"
            customFetchData={() => console.log("yesss")}
          />
        </CardBody>

        {!transationList.length ? <EmptyList text="No bills found yet!" /> : null}
      </Card>
    </React.Fragment>
  );
};

export default AllTransactions;
