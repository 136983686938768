import React, { useState } from "react";
import { Row } from "reactstrap";

export default function SkeletonSmall({ children }) {
  return (
    <React.Fragment>
      <Row className="skeleton-small">{children}</Row>
    </React.Fragment>
    // <React.Fragment>
    //   <div className="skeleton-uvh0zo6nab1">{children}</div>
    // </React.Fragment>
  );
}
