import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSearchParams, useNavigate } from "react-router-dom";

//redux
import DateField from "../../Components/Common/Fields/DateField";

import { handleValidDate } from "../../Utility/index.js";

const statusOptions = [
  { label: "Rejected", value: "rejected" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Success", value: "success" },
  { label: "Pending", value: "pending" },
  { label: "Fail", value: "fail" },
];

const paymentMethodOptions = [
  { label: "SIMPLY", value: "simply" },
  { label: "MTN", value: "MTN" },
  { label: "VODAFONE", value: "VODAFONE" },
];

const typeOptions = [
  { label: "Payout", value: "payout" },
  { label: "Payin", value: "payin" },
];

const CrmFilter = ({ show, onCloseClick }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: "",
      status: "",
      pmethod: "",
      reference: "",
      date_joined: "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter A Salutation"),
      first_name: Yup.string().notRequired("Please Enter A First Name"),
      last_name: Yup.string().notRequired("Please Enter A Last Name"),
      date_of_birth: Yup.date().notRequired("Please enter date of birth"),
      gender: Yup.string().notRequired(),
      language: Yup.string().notRequired("Please Enter A Language"),
    }),

    onSubmit: (client) => {},
  });

  const updateQueryParam = (value, name) => {
    // Create a new URLSearchParams object from the current searchParams
    const newSearchParams = new URLSearchParams(searchParams);

    // Delete existing parameters
    newSearchParams.delete("offset");
    newSearchParams.delete("limit");

    // Set the new parameter
    newSearchParams.set(name, value);

    // Update the URL with the new search parameters
    navigate({ search: newSearchParams.toString() });
    filterValidation.setFieldValue(`${name}`, value);
  };

  console.log(filterValidation.values, "FILTER_VALUESSA");

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Transaction Filters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            filterValidation.handleSubmit();
            return false;
          }}
          onReset={() => {
            filterValidation.handleReset();

            navigate("/transactions");
          }}
        >
          <OffcanvasBody>
            <div className="">
              <DateField
                md="12"
                value={filterValidation.values.date || ""}
                // onChange={filterValidation.handleChange}
                onChange={(e) => {
                  console.log(e, "CONCHAEEEE=====");

                  const date = handleValidDate(e);

                  console.log(date, "NEW_DATE");

                  updateQueryParam(date || "", "date_joined");
                }}
                validation={filterValidation}
                onBlur={filterValidation.handleBlur}
                label="Date"
                name="date"
              />
            </div>

            <div className="">
              <Label>Transaction type</Label>
              <Select
                md="12"
                // onChange={filterValidation.handleChange}
                isClearable
                onChange={(e) => updateQueryParam(e?.value || "", "type")}
                validation={filterValidation}
                onBlur={filterValidation.handleBlur}
                placeholder="Select a type..."
                label="Type"
                name="type"
                optional={true}
                options={typeOptions}
                value={{ value: filterValidation.values.type, label: filterValidation.values.type || "Select type" }}
              />
            </div>

            <div className="">
              <Label>Payment method</Label>
              <Select
                md="12"
                // onChange={filterValidation.handleChange}
                isClearable
                onChange={(e) => updateQueryParam(e?.value || "", "pmethod")}
                onBlur={filterValidation.handleBlur}
                placeholder="Select a payment method..."
                name="pmethod"
                optional={true}
                options={paymentMethodOptions}
                value={{
                  value: filterValidation.values.pmethod,
                  label: filterValidation.values.pmethod || "Select payment method",
                }}
              />
            </div>

            <div className="">
              <Label>Status</Label>
              <Select
                md="12"
                // onChange={filterValidation.handleChange}
                isClearable
                onChange={(e) => updateQueryParam(e?.value || "", "status")}
                onBlur={filterValidation.handleBlur}
                placeholder="Select a status..."
                name="status"
                optional={true}
                options={statusOptions}
                // value={filterValidation.values.status || ""}
                value={{
                  value: filterValidation.values.status,
                  label: filterValidation.values.status || "Select status",
                }}
              />
            </div>

            {/* <TextField
              md="12"
              mb="mb-3"
              value={filterValidation.values.last_name || ""}
              onChange={filterValidation.handleChange}
              validation={filterValidation}
              onBlur={filterValidation.handleBlur}
              placeholder="Enter last name"
              label="Last name"
              name="last_name"
            /> */}
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
