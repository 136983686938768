import { GET_ANALYTICS_SUCCESS } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
  usersList: [],
  teamsList: [],
};

const Analytics = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_SUCCESS:
      state = {
        ...state,
        success: true,
        data: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Analytics;
