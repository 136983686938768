import React from "react";

const FromCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const ToCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const DetailsCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const TransactionID = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const TypeCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Status = ({ status }) => {
  return (
    <React.Fragment>
      {status === "pending" ? (
        <span className="badge badge-soft-warning fs-11">
          <i className="ri-time-line align-bottom"></i> {status} settlement
        </span>
      ) : status === "full payment" ? (
        <span className="badge badge-soft-success fs-11">
          <i className="ri-checkbox-circle-line align-bottom"></i> {status}
        </span>
      ) : status === "settled" ? (
        <span className="badge badge-soft-info fs-11">
          <i className="ri-close-circle-line align-bottom"></i> {status}
        </span>
      ) : status === "part payment" ? (
        <span className="badge badge-soft-warning fs-11">
          <i className="ri-close-circle-line align-bottom"></i> {status}
        </span>
      ) : null}
    </React.Fragment>
  );
};

export { FromCol, ToCol, DetailsCol, TransactionID, TypeCol, Status };
