import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, Outlet } from "react-router-dom";

import { Route } from "react-router-dom";

const AuthProtected = (props) => {
  const [access_token, set_access_token] = useState("");

  const [isLoadding, setIsLoading] = useState(true);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");

    console.log(access_token, "ACCCESS_TOKEN");

    set_access_token(access_token);
    setIsLoading(false);
  }, []);

  /*
    redirect is un-auth access protected routes via url
    */

  if (!isLoadding && !access_token) {
    return <Navigate to="login" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
