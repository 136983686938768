import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

const Widgets = ({ data }) => {
  return (
    <React.Fragment>
      <Col md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="card-body bg-soft-success">
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <lord-icon
                    // src="https://cdn.lordicon.com/fhtaantg.json"
                    src="https://cdn.lordicon.com/yeallgsa.json"
                    trigger="loop"
                    colors="primary:#405189,secondary:#0ab39c"
                    style={{ width: "55px", height: "55px" }}
                  ></lord-icon>
                </div>
                <div className="flex-shrink-0">
                  <Link to="#" className="badge badge-soft-success badge-border">
                    TOTAL REQUEST PAYINS
                  </Link>
                </div>
              </div>
              <h3 className="mb-2">
                <span className="counter-value  btn-ghost-success" data-target="74858">
                  +{" "}
                  <CountUp
                    start={0}
                    end={data?.sent_payment_requests_total}
                    separator=","
                    prefix="<span>&#8373;</span>"
                    duration={1}
                    decimals={2}
                  />
                </span>
                <small className="text-muted fs-13">
                  {/* .{data?.sent_payment_requests_total?.toString().split(".")[1] || "0"} */}
                </small>
              </h3>
              <h6 className="text-muted mb-0">Total payment requests payin (last 7 days)</h6>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="card-body bg-soft-danger">
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <lord-icon
                    // src="https://cdn.lordicon.com/fhtaantg.json"
                    src="https://cdn.lordicon.com/qhviklyi.json"
                    trigger="loop"
                    colors="primary:#405189,secondary:#0ab39c"
                    style={{ width: "55px", height: "55px" }}
                  ></lord-icon>
                </div>
                <div className="flex-shrink-0">
                  <Link to="#" className="badge badge-soft-primary badge-border">
                    TOTAL REQUEST PAYOUTS
                  </Link>
                </div>
              </div>
              <h3 className="mb-2">
                <span className="counter-value  btn-ghost-danger" data-target="74858">
                  -{" "}
                  <CountUp
                    start={0}
                    end={data?.received_payment_requests_total?.toString().split(".")[0] || "00."}
                    separator=","
                    prefix="<span>&#8373;</span>"
                    duration={1}
                    decimals={2}
                  />
                </span>
                {/* <small className="text-muted fs-13">
                  .{data?.received_payment_requests_total?.toString().split(".")[1] || "0"}
                </small> */}
              </h3>
              <h6 className="text-muted mb-0">Total payment requests payout (last 7 days)</h6>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
