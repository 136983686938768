import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Client

// Users
import Users from "./users/reducer";

//Form advanced
import changeNumber from "./formAdvanced/reducer";

import Analytics from "./analytics/reducer";

import Billing from "./billings/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  changeNumber,

  Analytics,
  Billing,
});

export default rootReducer;
