import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Mousewheel } from "swiper";

const Widgets = () => {
  return (
    <React.Fragment>
      <Col xxl={6} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="flex-grow-1">
                <lord-icon
                  src="https://cdn.lordicon.com/fhtaantg.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "55px", height: "55px" }}
                ></lord-icon>
              </div>
              {/* <div className="flex-shrink-0">
                <p className="mb-0 text-muted">
                  <span className="badge bg-light text-success mb-0">
                    <i className="ri-arrow-up-line align-middle"></i> 7.05 %
                  </span>{" "}
                  vs. previous week
                </p>
              </div> */}
            </div>
            <h3 className="mb-2">
              <span className="counter-value" data-target="748">
                GHS <CountUp start={0} end={748} separator="," prefix="" duration={1} decimals={2} />
              </span>
              {/* <small className="text-muted fs-13">.68</small> */}
            </h3>
            <h6 className="text-muted mb-0">Total bill paid this month (GHS)</h6>
          </CardBody>
        </Card>
      </Col>

      <Col xxl={6} md={6}>
        <Swiper
          slidesPerView={1}
          spaceBetween={24}
          mousewheel={true}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Mousewheel]}
          className="default-swiper rounded"
        >
          <SwiperSlide>
            <Card className="card-animate">
              <div className="card-body bg-soft-warning">
                <div className="d-flex mb-3">
                  <div className="flex-grow-1">
                    <lord-icon
                      src="https://cdn.lordicon.com/vaeagfzc.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#0ab39c"
                      style={{ width: "55px", height: "55px" }}
                    ></lord-icon>
                  </div>
                  {/* <div className="flex-shrink-0">
                    <p className="mb-0 text-muted">
                      <span className="badge bg-light text-success mb-0">
                        <i className="ri-arrow-up-line align-middle"></i> 7.05 %
                      </span>{" "}
                      vs. previous week
                    </p>
                  </div> */}
                </div>
                <h3 className="mb-2">
                  GHS 224<small className="text-muted fs-13"></small>
                </h3>
                <h6 className="text-muted mb-0">Total bill paid last month (Previous Month)</h6>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
