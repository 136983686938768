import React from "react";
import { Col, Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllTransactions from "./AllTransactions";
import Widgets from "./Widgets";

import useSWR from "swr";

import StatementSkeleton from "../../Components/Skeletons/StatementSkeleton";

import { APIClient } from "../../utils/api_helper";
import { useSearchParams } from "react-router-dom";

const api = new APIClient();

const transactionListFetcher = async (url) => {
  const data = await api.get(url);

  return data;
};

const request_stats_fetcher = async (url) => {
  const { data } = await api.get(url);

  return data;
};

export default function Transactions({ user }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = new URLSearchParams(searchParams);

  // Access a specific query parameter
  const type = query.get("type") || "";
  const status = query.get("status") || "";
  const pmethod = query.get("pmethod") || "";
  const reference = query.get("reference") || "";
  const date = query.get("date_joined") || "";

  const offset = query.get("offset") || "";
  const limit = query.get("limit") || "";

  console.log(query, "QUERY__PARAMSSS");
  let {
    data: stats,
    error: request_error,
    isValidating: request_stats_validating,
    mutate: mutateStatistics,
  } = useSWR(`/transaction/statistics/transactions/`, request_stats_fetcher, {});

  //
  let {
    data: transactionsList,
    error: transactionList_error,
    isValidating: isTransactionListValidating,
    mutate: mutateTransaction,
  } = useSWR(
    `/transaction/?type=${type}&status=${status}&limit=${limit}&offset=${offset}&pmethod=${pmethod}&reference=${reference}&date_joined=${date}`,
    transactionListFetcher,
    {
      revalidateOnFocus: false, // revalidate when the page gains focus
      revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
    }
  );

  const mutateAll = () => {
    mutateTransaction();
    mutateStatistics();
  };

  document.title = "Transactions | SimplyPay";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Transactions" pageTitle="Payment requests" linkTo="/requests" />

          {/* <SettingsSkeleton> */}
          {/* {stats && ( */}
          <React.Fragment>
            <StatementSkeleton>{stats && <Widgets data={stats} />}</StatementSkeleton>

            <AllTransactions
              mutate={mutateAll}
              isValidating={isTransactionListValidating}
              transactionsList={transactionsList?.data}
              count={transactionsList?.count}
            />
          </React.Fragment>
          {/* )} */}
          {/* </SettingsSkeleton> */}
        </Container>
      </div>
    </React.Fragment>
  );
}
