import React, { useEffect, useState } from "react";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Cleave from "cleave.js/react";

import { Container, Form, Row, Col, Card, CardHeader, TabPane, ModalFooter, Alert, Spinner, Button } from "reactstrap";
import AddCardModal from "./Modals/AddCardModal";

import SkeletonSmall from "../../Components/Skeletons/SkeletonSmall";
import SelectField from "../../Components/Common/Fields/SelectField";
import { useDispatch, useSelector } from "react-redux";

import MsgToast from "../../Components/Common/MsgToast";
import ProccessingLoader from "../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../utils/api_helper";
import WalletCards from "./Cards/WalletCards";
import { getCards } from "../../store/actions";
import OtpModal from "./Modals/OtpModal";

const api = new APIClient();

const cardOptions = [
  {
    id: 1,
    description: "MTN CARD",
    accountNumber: "024 XXXXX 00",
    type: "mtn",

    progressBar: [
      { id: 1, bgColor: "bg-warning", width: "100%" },
      // { id: 2, bgColor: "bg-info", width: "30%" },
      { id: 3, width: "10%" },
    ],
  },
  {
    id: 2,
    description: "AT CARD",
    accountNumber: "027 XXXXX 00",
    type: "atl",

    progressBar: [
      { id: 1, bgColor: "bg-info", width: "60%" },
      { id: 2, bgColor: "bg-info", width: "0%" },
      { id: 3, width: "70%" },
    ],
  },
  {
    id: 3,
    description: "VODAFONE CARD",
    accountNumber: "020 XXXXX 00",
    type: "vod",

    progressBar: [
      { id: 1, bgColor: "bg-new-primary", width: "90%" },
      // { id: 2, bgColor: "bg-info", width: "5%" },
      { id: 3, width: "15%" },
    ],
  },
];

const DepositMoney = () => {
  const dispatch = useDispatch();
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const [defaultCard, setCardDefault] = useState({});

  const [walletOptions, setWalletOptions] = useState({});

  const [openOtpModal, setOpenOtpModal] = useState(false);

  const [reference, setReference] = useState("");

  const { cards, isFetchingCards } = useSelector((state) => ({
    cards: state.Profile.cards,
    isFetchingCards: state.Profile.isFetchingCards,
  }));

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [topBorderTab, settopBorderTab] = useState("1");

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding card, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });
  const togglemodal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (!cards) {
      dispatch(getCards());
    }
  }, []);

  useEffect(() => {
    const defaultCard = getDefaultCardOptions(cards);

    const walletOptions = getSelectOptions(cards);

    setWalletOptions(walletOptions);

    setCardDefault(defaultCard);
  }, [cards]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: "",
      reference: defaultCard?.value,
    },

    validationSchema: Yup.object({
      amount: Yup.string().required("Please select the amount you want to depositW"),
      reference: Yup.string().required("Please enter a reference"),
    }),

    onSubmit: async (values) => {
      try {
        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "DEPOSITING");
        setAlert({
          status: true,
          title: "Proccessing charge...",
          message: "Proccessing, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/transaction/charge/", values);
        console.log(data, "DEPOSITING");

        const status = data.charge_response.status;

        if (status === "send_otp") {
          setOpenOtpModal(true);
        } else if (status === "pay_offline") {
          setAlert({
            status: true,
            title: "INPUT PIN...",
            message: data.charge_response.display_text,
            // class: "spinner-border spinner-border-lg",
            class: "ri-information-line",
            textClass: "text-success",
            back_url: "/transactions",
          });
        }

        setSuccessMsg(data.charge_response.display_text);

        setReference(data.charge_response.reference);

        // setAlert({ ...alert, status: false });
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          dismissable: true,
        });
      }
    },
  });

  const getSelectOptions = (dataArray) => {
    if (!dataArray) return;

    let options = dataArray.map((arr) => {
      return { value: arr.reference, label: `${arr.account_number} ${arr.type.toUpperCase()}` };
    });

    return options;
  };

  function getDefaultCardOptions(cards) {
    if (!cards) return;

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].default) {
        // Break out of the loop when the condition is met
        return { value: cards[i].reference, label: `${cards[i].account_number} ${cards[i].type.toUpperCase()}` };
        break;
      }
    }
  }

  console.log(defaultCard, "DEFAULT__CARD");

  console.log(validation.errors, "CARD__OPTIONS");
  console.log(validation.values, "VALIDATION__VALUES");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="WITHDRAW MONEY" />

          <Row>
            <TabPane tabId={1} id="pills-bill-info">
              <CardHeader>
                <Row>
                  <Col>
                    <div>
                      <h5 className="mb-1">Withdraw from your simply account</h5>
                      <p className="text-muted mb-4">Please select wallet and enter the amount to withdraw</p>
                    </div>
                  </Col>

                  <Col md={3} className="d-flex justify-content-end">
                    <button
                      onClick={() => setOpenAddCardModal(!openAddCardModal)}
                      type="button"
                      className="btn btn-soft-success"
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i> Add wallet
                    </button>
                  </Col>
                </Row>
              </CardHeader>

              <Card className="p-4 border shadow-none mb-0 mb-4 ">
                <Col>
                  <h5 className="">Available wallets</h5>
                </Col>

                {isFetchingCards ? (
                  <p>
                    <SkeletonSmall></SkeletonSmall>
                  </p>
                ) : (
                  ""
                )}

                {!isFetchingCards && !cards?.length ? (
                  <Alert color="warning">
                    <strong> ! </strong>
                    You dont have a wallet yet, <b> Add a wallet </b>to make a deposit
                  </Alert>
                ) : (
                  ""
                )}

                <WalletCards cards={cards} cardOptions={cardOptions} />

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <Row className="gy-2">
                    <SelectField
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="select..."
                      label="Select wallet"
                      name="reference"
                      defaultValue={defaultCard}
                      // defaultValue={walletOptions?.length && walletOptions?.find((c) => c.value === defaultCard?.value)}
                      options={walletOptions}
                    />

                    <Col xl={6} md={6}>
                      <label htmlFor="cleave-numeral" className="form-label">
                        Amount
                      </label>

                      <Cleave
                        placeholder="Enter amount to send"
                        onChange={validation.handleChange}
                        defaultValue={defaultCard}
                        label="Select wallet"
                        name="amount"
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: "thousand",
                        }}
                        value={""}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <ModalFooter>
                    {validation.isSubmitting ? (
                      <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">Loading...</span>
                          <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        </span>
                      </Button>
                    ) : (
                      <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab">
                        <i className="ri-send-plane-line label-icon align-middle fs-16 ms-2"></i>
                        DEPOSIT
                      </button>
                    )}
                  </ModalFooter>
                </Form>
              </Card>

              {/* <div className="d-flex align-items-start gap-3 mt-4">
                <button
                  type="button"
                  className="btn btn-success btn-label right ms-auto nexttab"
                  onClick={() => {
                    console.log("CONFIRMING SEND");
                  }}
                >
                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                  Proceed
                </button>
              </div> */}
            </TabPane>
          </Row>
        </Container>
      </div>

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      <AddCardModal openAddCardModal={openAddCardModal} setOpenAddCardModal={setOpenAddCardModal} />

      <OtpModal openOtpModal={openOtpModal} setOpenOtpModal={setOpenOtpModal} reference={reference} />

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default DepositMoney;
