import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Modal, ModalFooter, ModalHeader, ModalBody, Spinner, Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { APIClient } from "../../../utils/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../../../store/actions";

const api = new APIClient();

const WalletCards = ({ cards, cardOptions }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [cardRef, setCardRef] = useState("");

  const [isSettingDefault, setIsSettingDefault] = useState(false);

  // useEffect(() => {
  //   dispatch(getCards());
  // }, [dispatch]);

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding card, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const setCardDefault = async (reference) => {
    try {
      console.log(reference, "REQUEST__STATS__DATAA");
      setIsSettingDefault(true);

      setAlert({
        status: true,
        title: "Proccessing...",
        message: "Adding card, please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      setErrorMsg("");
      setSuccessMsg("");
      console.log(reference, "VALUESS__REQUESTTS");
      const data = await api.post("/wallets/card/default/", { reference: reference });
      console.log(data, "REQUEST__STATS__DATAA");

      // setRequestSuccess(data.message);

      dispatch(getCards());

      setSuccessMsg(data.message);
      setErrorMsg("");

      // toggleModal();

      setAlert({ ...alert, status: false });

      setTimeout(() => {
        setModal(!modal);
      }, 2000);
      setIsSettingDefault(false);
    } catch (error) {
      console.log(error, "LOGIN");
      setErrorMsg("");
      setIsSettingDefault(false);

      let errMsg = error?.response?.data?.message;

      console.log(errMsg, "ERRRRRRRRRRRR");
      errMsg = errMsg || "internal server occured";

      setErrorMsg(errMsg);

      console.log(errMsg, "ER__MERSG");

      setAlert({
        status: true,
        title: "Sorry!",
        message: errMsg,
        //class: "ri-information-line",
        class: "ri-error-warning-line ri-3xl",
        textClass: "text-primary",
        //back_url: "/accounts/plans",
        dismissable: true,
      });
    }
  };

  const bgColors = {
    vodafone: "bg-soft-warning",
    mtn: "bg-soft-primary",
    at: "bg-soft-info",
  };

  const openModal = (ref) => {
    setCardRef(ref);
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <Row>
        {/* <SkeletonSmall> */}
        {cards?.map((item, key) => (
          <Col
            lg={3}
            key={key}
            onClick={() => {
              if (!item.default) {
                openModal(item.reference);
              }
            }}
          >
            <Card className={`${bgColors[item.type]} card-animate ribbon-box `} role="button">
              {item.default ? (
                <div className="ribbon-two ribbon-two-primary">
                  <span>Default</span>
                </div>
              ) : null}

              <CardBody>
                <h5 className="text-muted text-uppercase fs-13">
                  {item.description}
                  <i className={"fs-18 float-end align-middle ri-arrow-up-circle-line text-success"}></i>
                </h5>

                <p className="text-muted">
                  {item.account_number} <span className="text-muted fs-10 float-end">8/99</span>
                </p>
              </CardBody>
              <div className="progress animated-progess rounded-bottom rounded-0" style={{ height: "6px" }}>
                {cardOptions
                  ?.find((c) => c.type === item.type)
                  .progressBar.map((item, key) => (
                    <div
                      className={"progress-bar rounded-0 " + item.bgColor}
                      role="progressbar"
                      style={{ width: item.width }}
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      key={key}
                    ></div>
                  ))}
              </div>
            </Card>
          </Col>
        ))}
        {/* </SkeletonSmall> */}
      </Row>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={() => {
          setModal(!modal);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">Are you Sure You want to set this card as default ?</p>
            </div>
          </div>

          <ModalFooter className="mt-6">
            {isSettingDefault ? (
              <Button disabled={isSettingDefault} color="info" className="btn-load">
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">setting default, please wait...</span>
                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                    {" "}
                    setting default, please wait...{" "}
                  </Spinner>
                </span>
              </Button>
            ) : (
              <button
                onClick={() => {
                  setCardDefault(cardRef);
                }}
                type="submit"
                className="btn btn-primary btn-label right ms-auto nexttab"
              >
                <i className="ri-send-plane-line label-icon align-middle fs-16 ms-2"></i>
                Yes, Set as default
              </button>
            )}
          </ModalFooter>
        </ModalBody>
        {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
      </Modal>
    </React.Fragment>
  );
};

export default WalletCards;
