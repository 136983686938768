import React, { useState } from "react";
import { Row } from "reactstrap";

export default function SettingsSkeleton({ children }) {
  return (
    <React.Fragment>
      <Row className="skeleton-uvh0zo6nab1">{children}</Row>
    </React.Fragment>
  );
}
