import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllRequests from "./AllRequests";
import Widgets from "./Widgets";

import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";

import { APIClient } from "../../utils/api_helper";
import { useLocation } from "react-router-dom";

import useSWR from "swr";
import StatementSkeleton from "../../Components/Skeletons/StatementSkeleton";

// import { useRouter } from "next/router";
//  import Filter from "./Filter";

const api = new APIClient();

const fetcher = async (url) => {
  const data = await api.get(url);

  return data;
};

const request_stats_fetcher = async (url) => {
  const { data } = await api.get(url);

  return data;
};

export default function Requests({ user }) {
  // const router = useRouter();
  // const { pathname, query } = router;

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // Access a specific query parameter
  const type = query.get("type") || "";
  const status = query.get("status") || "";

  const reference = query.get("reference") || "";

  const offset = query.get("offset") || "";
  const date = query.get("date_joined") || "";
  const amount = query.get("amount") || "";

  // const limit = query.get("limit");

  let {
    data: payment_requests_stats,
    error: request_error,
    isValidating: request_stats_validating,
    mutate: mutateRequestsStats,
  } = useSWR(`/transaction/statistics/payment_requests/`, request_stats_fetcher, {});

  let {
    data: requests,
    error,
    isValidating,
    mutate: mutateRequests,
  } = useSWR(
    `/transaction/charge/simply/?type=${type}&status=${status}&offset=${offset}&reference=${reference}&date_joined=${date}&amount=${amount}`,
    fetcher
  );

  const mutateAll = () => {
    mutateRequestsStats();
    mutateRequests();
  };

  document.title = "Payment Requests | SimplyPay";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Payment Requests" linkTo={"/pay-bills"} pageTitle="Pay Bills" />

          <React.Fragment>
            <StatementSkeleton>{payment_requests_stats && <Widgets data={payment_requests_stats} />}</StatementSkeleton>
            <AllRequests mutate={mutateAll} isValidating={isValidating} data={requests} />
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
}
