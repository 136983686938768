import React, { useState } from "react";
import { Row } from "reactstrap";

export default function StatementSkeleton({ children }) {
  return (
    <React.Fragment>
      <Row className="skeleton-settings">
        {/* <div className="skeleton-statement"></div> */}

        {children}
      </Row>
    </React.Fragment>
  );
}
