let URLbaseAPI = "http://127.0.0.1:8000/api";

// let URLbaseAPI = "https://8f05-41-139-60-46.ngrok-free.app/api";

//8f05-41-139-60-46.ngrok-free.app/
if (process.env.NODE_ENV === "development") {
  URLbaseAPI = URLbaseAPI;
}

if (process.env.NODE_ENV === "production") {
  // URLbaseAPI = "https://simm-simplyp.koyeb.app/api";
  URLbaseAPI = "https://my-spay.com/api";

  // URLbaseAPI = "https://spaybackend.up.railway.app/api";
}

module.exports = URLbaseAPI;
