import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import EcgModal from "./Modals/EcgModal";

const billsItems = [
  {
    name: "EC GHANA",
    img: "/static/icons/ecg.png",
  },

  {
    name: "GOTV",
    img: "/static/icons/gotv.svg",
  },
  {
    name: "WATER",
    img: "/static/icons/water.svg",
  },
  {
    name: "DSTV",
    img: "/static/icons/dstv.png",
  },
];

const modalItems = [
  {
    name: "EC GHANA",
    img: "/static/icons/ecg.png",
    title: "Enter the meter number to continue",
    label: "ENTER METER NUMBER",
    placeholder: "Enter meter number",
    serviceCode: "GH-PREPAID-ELEC",
  },

  {
    name: "GOTV",
    img: "/static/icons/gotv.svg",
    title: "Enter the card number to continue",
    label: "ENTER CARD NUMBER",
    placeholder: "Enter card number",
    serviceCode: "GH-GOTV",
  },
  {
    name: "WATER",
    img: "/static/icons/water.svg",
    title: "Enter the meter number to continue",
    label: "ENTER METER NUMBER",
    placeholder: "Enter meter number",
    serviceCode: "GH-DSTV",
  },
  {
    name: "DSTV",
    img: "/static/icons/dstv.png",
    title: "Enter the card number to continue",
    label: "ENTER CARD NUMBER",
    placeholder: "Enter card number",
    serviceCode: "GH-DSTV",
  },

  // {
  //   name: "GOTV",
  //   img: "/static/icons/gotv.svg",
  // },
  // {
  //   name: "WATER",
  //   img: "/static/icons/water.svg",
  // },
  // {
  //   name: "DSTV",
  //   img: "/static/icons/dstv.png",
  // },
];

const BillsItems = (props) => {
  const [openEcgModal, setOpenEcgModal] = useState(false);
  const [modalItem, setModalItem] = useState({});

  const handleOpenEcgModal = (item) => {
    const modalItem = modalItems.find((i) => i.name === item);

    setModalItem(modalItem);

    setOpenEcgModal(!openEcgModal);
  };

  console.log(modalItem, "MODALLLL__ITEM");

  return (
    <React.Fragment>
      <div className="">
        <li className="savedcards__">
          <Row className="mt-4">
            {billsItems?.map((bill, i) => {
              return (
                <Col
                  key={i}
                  xl={6}
                  lg={6}
                  className="col-6"
                  role="button"
                  onClick={() => handleOpenEcgModal(bill.name)}
                >
                  <Card className="ribbon-box right overflow-hidden card-animate primary">
                    <span className="badge badge-outline-primary">
                      <CardBody className="text-center">
                        <img src={bill.img} alt="" height="45" />
                        <h5 className="mb-1 mt-2">{bill.name}</h5>
                      </CardBody>
                    </span>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </li>
      </div>

      <EcgModal toggleModal={() => setOpenEcgModal(!openEcgModal)} isModalOpen={openEcgModal} modalItem={modalItem} />
    </React.Fragment>
  );
};

export default BillsItems;
