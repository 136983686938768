import {
  GET_USERS_LIST,
  GET_USER,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  ADD_NEW_USER,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  RESET_USER_FLAG,
} from "./actionType";

export const UsersApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const UsersApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getUsersList = (params) => ({
  type: GET_USERS_LIST,
  payload: params,
});

export const getUser = (user) => ({
  type: GET_USER,
  payload: user,
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserFail = (user) => ({
  type: UPDATE_USER_FAIL,
  payload: user,
});

export const addNewUser = (user) => ({
  type: ADD_NEW_USER,
  payload: user,
});

export const addNewUserSuccess = (user) => ({
  type: ADD_NEW_USER_SUCCESS,
  payload: user,
});

export const addNewUserFail = (error) => ({
  type: ADD_NEW_USER_FAIL,
  payload: error,
});

export const deleteUser = (user) => ({
  type: DELETE_USER,
  payload: user,
});

export const deleteUserSuccess = (user) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const resetUserFlag = () => ({
  type: RESET_USER_FLAG,
});
