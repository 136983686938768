import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/RequestsTableContainr";

import { TransactionID, Status } from "./TransactionsCol";
import { getFees } from "../../store/actions";
import ListLoader from "../../Components/modules/ListLoader";
import EmptyList from "../../Components/EmptyList";
import RefreshButton from "../../Components/Loaders/RefreshButton";

const AllFees = ({ isValidating, mutate }) => {
  const dispatch = useDispatch();

  const { fees, isFetchingFee } = useSelector((state) => ({
    fees: state.Billing.fees,
    isFetchingFee: state.Billing.isFetchingFee,
  }));

  console.log(fees, "feesfeesfeesfeesfees");

  useEffect(() => {
    dispatch(getFees());
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   Header: "School",
      //   accessor: "school",
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return <TransactionID {...cellProps} />;
      //   },
      // },
      {
        Header: "School name",
        accessor: "school.name",
        filterable: false,
      },
      {
        Header: "Student ID",
        accessor: "student_id",
        filterable: false,
      },

      {
        Header: "Academic year",
        accessor: "academic_year",
        filterable: false,
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Date created",
        filterable: true,
        Cell: (cellProps) => {
          const approved = cellProps.row.original?.date_created;
          return (
            <React.Fragment>
              <span className="">{approved}</span>
            </React.Fragment>
          );
        },
      },

      {
        Header: "Time",
        Cell: (transaction) => (
          <>
            {/* {handleValidDate(transaction.row.original.date)},{" "} */}
            <small className="text-muted">{transaction.row.original.created_at_arrow}</small>
          </>
        ),
      },

      {
        Header: "Source",
        accessor: "payment_method",
        filterable: false,
        Cell: (cellProps) => {
          return <TransactionID {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status status={cellProps.row.original.status} />;
        },
      },

      {
        Header: "Action",
        Cell: (cell) => {
          const id = cell.row.original.uuid;
          return (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`/fee/${id}`} className="d-inline-block auth-logo">
                  <DropdownItem>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i> View
                  </DropdownItem>
                </Link>

                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <Row className="align-items-center mb-4 g-3">
        <div className="col-sm-auto ms-auto col-auto">
          <div className="d-flex gap-2">
            <Link to="/pay-fees" data-bs-toggle="modal" className="btn btn-info">
              Pay School fees
            </Link>
          </div>
        </div>
      </Row>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">School fees history</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <RefreshButton isValidating={isValidating} mutate={mutate} />

                <div className="search-box">
                  <input type="text" className="form-control search" placeholder="Search by reference..." />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <button className="btn btn-success">
                  <i className="ri-equalizer-line align-bottom me-1"></i>
                  Filters
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            columns={columns}
            data={fees?.data || []}
            isGlobalFilter={false}
            isAddUserList={false}
            customPageSize={8}
            className="custom-header-css"
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
            thClass="table-light text-muted"
            customFetchData={() => console.log("yesss")}
          />
        </CardBody>

        {!isFetchingFee && !fees?.data.length ? <EmptyList text="No Fees found yet!" /> : null}

        {isFetchingFee ? <ListLoader /> : null}
      </Card>
    </React.Fragment>
  );
};

export default AllFees;
