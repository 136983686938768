import React from "react";

//constants
import { layoutModeTypes } from "../constants/layout";

const SimplyHead = ({}) => {
  return (
    <div className="text-cente">
      <h5 className="login__head">
        <img src="/static/s-logo-orange.png" alt="" className="rounded-circle avatar-xl" />
        <span> Simply Pay</span>
      </h5>
      {/* <p className="login__smalltext">Please fill your details to access your account.</p> */}
    </div>
  );
};

export default SimplyHead;
