import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";

// User
import UsersSaga from "./users/saga";

import ForgetPasswordSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

import Billings from "./billings/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetPasswordSaga),

    fork(ProfileSaga),

    fork(UsersSaga),
    fork(Billings),
  ]);
}
