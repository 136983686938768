import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  Form,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { FromCol, ToCol, DetailsCol, TransactionID, TypeCol, Status } from "../Transactions/TransactionsCol";

//Import Icons
import FeatherIcon from "feather-icons-react";
import Select from "react-select";

//Import actions

//redux
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../Components/Common/Loader";
import MsgToast from "../../Components/Common/MsgToast";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/RequestsTableContainr";

const RecentTransactionsList = ({ transactionsList }) => {
  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const currentUserId = user?.uuid;

  //delete transaction
  const [deleteModal, setDeleteModal] = useState(false);
  const [isStatus, setisStatus] = useState(null);

  const handleisStatus = (isStatus) => {
    setisStatus(isStatus);
  };

  const allstatus = [
    {
      options: [
        { label: "Status", value: "Status" },
        { label: "All", value: "All" },
        { label: "Unpaid", value: "Unpaid" },
        { label: "Paid", value: "Paid" },
        { label: "Cancel", value: "Cancel" },
        { label: "Refund", value: "Refund" },
      ],
    },
  ];

  // const onClickDelete = () => {
  //   setDeleteModal(true);
  // };

  const MethodImage = {
    PAYPAL: <img src="/static/paypal.png" alt="" />,
    MTN: <img src="/static/mtn-logo.png" alt="" />,
    mtn: <img src="/static/mtn-logo.png" alt="" />,

    "MTN Mobile": <img src="/static/mtn-logo.png" alt="" />,

    TIGO: <img src="/static/tigo-logo.png" alt="" />,
    VODAFONE: <img src="/static/vodafone-logo.png" alt="" />,
    vod: <img src="/static/icons/vodafone.png" alt="" />,

    // simply: <img src="/static/logo.png" alt="" />,

    simply: <img src="/static/s-logo-orange.png" alt="" />,
  };

  //Column
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Reference",
        accessor: "reference",
        filterable: false,
        Cell: (cell) => {
          const id = cell.row.original.uuid;

          return (
            <Link to={`/transactions/${id}`} className="fw-medium  text-dark">
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "P. method",
        Cell: (transaction) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 avatar-xs me-2">
                <div className="simgplyimg rounded-circle fs-13">
                  {MethodImage[transaction.row.original.payment_method]}
                  {/* <span>{transaction.row.original.payment_method}</span> */}
                </div>
              </div>
              {transaction.row.original.payment_method}
            </div>
          </>
        ),
      },
      // {
      //   Header: "P. method",
      //   accessor: "payment_method",
      //   filterable: false,
      // },

      {
        Header: "Time",
        Cell: (transaction) => (
          <>
            {/* {handleValidDate(transaction.row.original.date)},{" "} */}
            <small className="text-muted">{transaction.row.original.created_at_arrow}</small>
          </>
        ),
      },
      // {
      //   Header: "AMOUNT",
      //   accessor: "amount",
      //   filterable: false,
      // },
      {
        Header: "Amount",
        Cell: (transaction) => {
          const simply_transaction = (type) => {
            return type === "simply";
          };

          const is_user_payer = (transaction) => {
            return transaction?.payer?.uuid === currentUserId;
          };

          const is_simply_transaction = simply_transaction(transaction.row.original.payment_method);

          const isCurrentUserPayer = is_user_payer(transaction.row.original);

          return (
            <>
              <h6 className={`text amount mb-1  ${isCurrentUserPayer ? "btn-ghost-danger" : "btn-ghost-success"}`}>
                {isCurrentUserPayer && is_simply_transaction ? "-" : "+"}
                <span>&#8373;</span>
                {transaction.row.original.amount}
              </h6>
              <p className="text-muted mb-0">{transaction.row.original.amount1}</p>
            </>
          );
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: (cell) => {
      //     switch (cell.value) {
      //       case "Paid":
      //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
      //       case "Unpaid":
      //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
      //       case "Cancel":
      //         return <span className="badge text-uppercase badge-soft-danger"> {cell.value} </span>;
      //       default:
      //         return <span className="badge text-uppercase badge-soft-info"> {cell.value} </span>;
      //     }
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status status={cellProps?.row?.original?.status} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const id = cellProps.row.original.uuid;

          return (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`/transactions/${id}`} className="d-inline-block auth-logo">
                  <DropdownItem>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i> View
                  </DropdownItem>
                </Link>

                {/* <DropdownItem href="/apps-transactions-create">
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                </DropdownItem> */}

                {/* <DropdownItem href="/#">
                  <i className="ri-download-2-line align-bottom me-2 text-muted"></i> Download
                </DropdownItem> */}

                <DropdownItem divider />

                {/* <DropdownItem
                  href="#"
                  onClick={() => {
                    const transactionData = cellProps.row.original;
                    // onClickDelete(transactionData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [transactionsList]
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        // onDeleteClick={() => handleDeletetransaction()}
        // onCloseClick={() => setDeleteModal(false)}
      />
      <TableContainer
        columns={columns}
        data={transactionsList || []}
        isGlobalFilter={false}
        isAddUserList={false}
        customPageSize={111}
        // className="custom-header-css"
        className="custom-header-css"
        divClass="table-responsive table-card mb-4"
        tableClass="align-middle table-nowrap mb-0"
        theadClass="table-light table-nowrap"
        thClass="table-light text-muted"
        customFetchData={() => "re"}
      />
    </React.Fragment>
  );
};

export default RecentTransactionsList;
