import React, { useState } from "react";
import Webcam from "react-webcam";
import { useCallback, useRef } from "react";

export default function CustomWebcam({ validation }) {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  // console.log(imgSrc, "WEBCAM_IMAGE");

  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    validation.setFieldValue("requester_cam", imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  // code
  return (
    <div className="container">
      {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <div className="webcam">
          <Webcam ref={webcamRef} />
        </div>
      )}
      <div className="btn-container">
        {imgSrc ? (
          <button type="button" className="webcam__button" onClick={retake}>
            Retake photo
          </button>
        ) : (
          <button type="button" className="webcam__button" onClick={capture}>
            Capture photo
          </button>
        )}
      </div>
    </div>
  );
}
