import { GET_FEES, GET_FEES_SUCCESS } from "./actionType";

export const getFeesSuccess = (data) => ({
  type: GET_FEES_SUCCESS,
  payload: data,
});

export const getFees = (error) => ({
  type: GET_FEES,
  payload: error,
});
