import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import MsgToast from "../../../Components/Common/MsgToast";

import { APIClient } from "../../../utils/api_helper";
import { profileSuccess } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardBody, CardHeader, Col, Input, Label, Row } from "reactstrap";

const api = new APIClient();

const ManageSecurityTab = ({ user }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState("");

  console.log(user, "PRFOILE_USER");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user?.first_name,
      lastName: user?.last_name,
      // phone: user?.phone,
      // email: user?.email,
      dateOfBirth: user?.date_of_birth,
      city: user?.city,
      address: user?.address,
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter your last name"),
      dateOfBirth: Yup.string().required("Please select the date of birth"),
    }),

    onSubmit: async (values) => {
      console.log(values, "CHANGING_USER");
      setSuccessMsg("");

      setErrorMsg("");
      try {
        const data = await api.update("/users/user/", values);

        console.log(data, "CHANGED_PASSWORD");

        setSuccessMsg(data.message);
        // validation.resetForm();

        dispatch(profileSuccess(data.user));
      } catch (error) {
        // validation.resetForm();

        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <div className="mb-4 pb-2">
              <h5 className="card-title text-decoration-underline mb-3">Security:</h5>
              <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                <div className="flex-grow-1">
                  <h6 className="fs-14 mb-1">Two-factor Authentication</h6>
                  <p className="text-muted">
                    Two-factor authentication is an enhanced security meansur. Once enabled, you'll be required to give
                    two types of identification when you log into Google Authentication and SMS are Supported.
                  </p>
                </div>
                <div className="flex-shrink-0 ms-sm-3">
                  <Link to="#" className="btn btn-sm btn-primary">
                    Enable Two-facor Authentication
                  </Link>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <h5 className="card-title text-decoration-underline mb-3">Application Notifications:</h5>
              <ul className="list-unstyled mb-0">
                <li className="d-flex mt-2">
                  <div className="flex-grow-1">
                    <Label className="form-check-label fs-14" htmlFor="desktopNotification">
                      Enable Request facial verification
                    </Label>
                    <p className="text-muted">
                      Users will be required to verfiy their face whenever they send you payment requests
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="desktopNotification"
                        defaultChecked
                      />
                    </div>
                  </div>
                </li>
                <li className="d-flex mt-2">
                  <div className="flex-grow-1">
                    <Label className="form-check-label fs-14" htmlFor="emailNotification">
                      Allow sms notification
                    </Label>
                    <p className="text-muted">
                      {" "}
                      You will receive notification whenever a payment requests or money is sent to you
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="emailNotification"
                        defaultChecked
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div>
                                    <h5 className="card-title text-decoration-underline mb-3">Delete This Account:</h5>
                                    <p className="text-muted">
                                      Go to the Data & Privacy section of your profile Account. Scroll to "Your data &
                                      privacy options." Delete your Profile Account. Follow the instructions to delete
                                      your account :
                                    </p>
                                    <div>
                                      <Input
                                        type="password"
                                        className="form-control"
                                        id="passwordInput"
                                        placeholder="Enter your password"
                                        defaultValue="make@321654987"
                                        style={{ maxWidth: "265px" }}
                                      />
                                    </div>
                                    <div className="hstack gap-2 mt-3">
                                      <Link to="#" className="btn btn-soft-danger">
                                        Close & Delete This Account
                                      </Link>
                                      <Link to="#" className="btn btn-light">
                                        Cancel
                                      </Link>
                                    </div>
                                  </div> */}
          </Row>
        </CardBody>
      </Card>

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default ManageSecurityTab;
