import React, { useState } from "react";

import FundModal from "../Modals/FundModal";
import { Link } from "react-router-dom";

import eyeImg from "../../assets/eye.png";
import deposit from "../../assets/icons/deposit.svg";
import withdraw from "../../assets/icons/withdraw.svg";
import RefreshButton from "../Loaders/RefreshButton";

export default function Balancecardv2({ walletBalance, isValidating, mutate }) {
  const isBalanceAvaiable = walletBalance ? "balancecardv2" : "balancecardv2 active";

  return (
    <div className={isBalanceAvaiable}>
      <div className="balancecardv2__container">
        <div className="balancecardv2__list">
          <div className="balancecardv2__top">
            <p className="balancecardv2__top--text">Simply Account Balance</p>
            <div className="balancecardv2__cardcurrency"></div>
          </div>
          <div className="balancecardv2__numbers">
            <div className="balancecardv2__numbers--digits">&#8373; {walletBalance}</div>

            <div className="d-flex p-2">
              <RefreshButton isValidating={isValidating} mutate={mutate} />
              <img src={eyeImg} alt="" />
            </div>
          </div>
          <div className="balancecardv2__analytics">
            <ul>
              {/* <li>
                <Link to="/withdraw" className="fw-semibold text-primary">
                  <div className="flex-shrink-0 align-self-end">
                    <figure>
                      <icon className=" ri-send-plane-fill"></icon>
                      <img src="/static/help.png" alt="" />
                    </figure>

                    <span>Withdraw</span>
                  </div>
                </Link>
              </li> */}
              <li>
                <Link to="/withdraw-money" className="fw-semibold text-primary">
                  <div className="flex-shrink-0 text-align-center">
                    <figure>
                      <img
                        src={withdraw}
                        alt=""
                        style={{
                          filter:
                            "invert(99%) sepia(0%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%)",
                        }}
                      />
                    </figure>

                    <span>Withdraw</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/deposit-money" className="fw-semibold text-primary">
                  <div className="flex-shrink-0 text-align-center">
                    <figure>
                      <img
                        src={deposit}
                        alt=""
                        style={{
                          filter:
                            "invert(99%) sepia(0%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%)",
                        }}
                      />
                    </figure>

                    <span>Deposit</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
