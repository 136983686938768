import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SwiperCore, { Autoplay } from "swiper";

import { Status } from "./TransactionsCol";

import { profileSuccess } from "../../store/actions";
import { useDispatch } from "react-redux";

import { APIClient } from "../../utils/api_helper";
import AccountVerificationModal from "./Modals/AccountVerificationModal";

const api = new APIClient();

const UserInformation = () => {
  SwiperCore.use([Autoplay]);

  const [openVerificationModal, setOpenVerificationModal] = useState(false);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const handleProfilePic = async (e) => {
    try {
      const profile_pic = e.target.files[0];

      if (!profile_pic) return;

      const userFormData = new FormData();

      userFormData.set("profile_pic", profile_pic);

      const data = await api.update("/users/user/", userFormData);

      console.log(data, "CHANGING__profile_pic");

      // setSuccessMsg(data.message);
      // validation.resetForm();

      dispatch(profileSuccess(data.user));
    } catch (error) {
      // validation.resetForm();

      console.log(error, "LOGIN");

      let errMsg = error?.response?.data?.message;
      console.log(errMsg, "ERRRRRRRRRRRR");
      errMsg = errMsg || "internal server occured";
      // setErrorMsg(errMsg);
      console.log(errMsg, "ER__MERSG");
    }
  };

  document.title = "Profile";

  return (
    <React.Fragment>
      <div className="profile-foreground position-relative mx-n4 mt-n4">
        <div className="profile-wid-bg">{/* <img src={profileBg} alt="" className="profile-wid-img" /> */}</div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto">
            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
              <div className="avatar-lg">
                <img src={user?.profile_pic} alt="user-img" className="img-thumbnail rounded-circle" />
              </div>

              <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                <Input
                  onChange={(e) => handleProfilePic(e)}
                  id="profile-img-file-input"
                  type="file"
                  className="profile-img-file-input"
                />
                <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                  <span className="avatar-title rounded-circle bg-light text-body">
                    <i className="ri-camera-fill"></i>
                  </span>
                </Label>
              </div>
            </div>
          </div>

          <Col>
            <div className="p-2">
              <h3 className="text-white mb-1">
                {user?.first_name} {user?.last_name}
              </h3>
              <p className="text-white-75">{user?.email}</p>
              <div className="hstack text-white gap-1">
                <div className="me-2">
                  <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                  {user?.phone}
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <Row className="text text-white-50 text-center">
              <Col lg={6} xs={4}>
                <div className="p-2">
                  <Status status={user?.verify_face} />
                </div>
              </Col>
            </Row>

            {user?.verify_face !== "success" ? (
              <div className="text-end">
                <button onClick={() => setOpenVerificationModal(true)} type="submit" className="btn btn-danger">
                  verify your account
                </button>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>

      <AccountVerificationModal isModalOpen={openVerificationModal} openModal={setOpenVerificationModal} />
    </React.Fragment>
  );
};

export default UserInformation;
