import * as moment from "moment";

// Date & Time Format
export const dateFormat = () => {
  var d = new Date(),
    months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
};

export const timeFormat = () => {
  let d = new Date();
  let minutes = d.getMinutes().toString().length === 1 ? "0" + d.getMinutes() : d.getMinutes();
  let hours = d.getHours().toString() % 12 || 12;
  hours = hours <= 9 ? (hours = "0" + hours) : hours;
  let ampm = d.getHours() >= 12 ? "PM" : "AM";
  return hours + ":" + minutes + ampm;
};

export const handleValidDate = (date) => {
  if (!date || !date?.length) return null;
  //if (!moment(date).isValid()) return null;
  const date1 = moment(new Date(date)).format("YYYY-MM-DD");

  return date1;
};

export const handleValidDateAlt = (date) => {
  const date1 = moment(new Date(date)).format("YYYY-MM-DD");
  return date1;
};

export const handleValidTimeAlt = (date) => {
  const time = moment(new Date(date)).format("hh:mm:ss");
  return time;
};

export const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};
