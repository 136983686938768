import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SwiperCore, { Autoplay } from "swiper";

import ManagePasswordTab from "./Tabs/ManagePasswordTab";
import ManageProfileTab from "./Tabs/ManageProfileTab";
import SupportTab from "./Tabs/SupportTab";

import SettingsSkeleton from "../../Components/Skeletons/SettingsSkeleton";
import ManageWalletTab from "./Tabs/ManageWalletTab";
import { Status } from "./TransactionsCol";
import ManageSecurityTab from "./Tabs/ManageSecurityTab";
import UserInformation from "./UserInformation";

const SimplePage = () => {
  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.title = "Profile";

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <SettingsSkeleton>
            {user && (
              <div className="page-content">
                <Container fluid>
                  {/*  */}

                  <UserInformation />

                  {/*  */}
                  <Row>
                    <Col lg={12}>
                      <div>
                        <div className="d-flex">
                          <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                            <NavItem>
                              <NavLink
                                // href="#overview-tab"
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => {
                                  toggleTab("1");
                                }}
                              >
                                <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                                <span className="d-none d-md-inline-block">Personal information</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                // href="#activities"
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                  toggleTab("2");
                                }}
                              >
                                <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                                <span className="d-none d-md-inline-block">KYC documentation</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                // href="#projects"
                                className={classnames({ active: activeTab === "3" })}
                                onClick={() => {
                                  toggleTab("3");
                                }}
                              >
                                <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                                <span className="d-none d-md-inline-block">Privacy and Secuirty</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                // href="#documents"
                                className={classnames({ active: activeTab === "4" })}
                                onClick={() => {
                                  toggleTab("4");
                                }}
                              >
                                <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                                <span className="d-none d-md-inline-block">Contact support</span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                // href="#documents"
                                className={classnames({ active: activeTab === "5" })}
                                onClick={() => {
                                  toggleTab("5");
                                }}
                              >
                                <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                                <span className="d-none d-md-inline-block">Change password</span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === "6" })}
                                onClick={() => {
                                  toggleTab("6");
                                }}
                              >
                                <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                                <span className="d-none d-md-inline-block">Wallet Management</span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>

                        <TabContent activeTab={activeTab} className="pt-4">
                          <TabPane tabId="1">
                            <Row>
                              <Col xxl={12}>
                                <ManageProfileTab user={user} />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Card>
                              <CardBody>
                                <h5 className="card-title mb-3">KYC documentation</h5>
                                <div className="acitivity-timeline"></div>
                              </CardBody>
                            </Card>
                          </TabPane>

                          <TabPane tabId="3">
                            <ManageSecurityTab />
                          </TabPane>

                          <TabPane tabId="4">
                            <Card>
                              <CardBody>
                                <div className="d-flex align-items-center mb-4">
                                  <h5 className="card-title flex-grow-1 mb-0">Contact support</h5>
                                </div>
                                <Row>
                                  <SupportTab />
                                </Row>
                              </CardBody>
                            </Card>
                          </TabPane>

                          <TabPane tabId="5">
                            <ManagePasswordTab />
                          </TabPane>

                          <TabPane tabId="6">
                            <ManageWalletTab />
                          </TabPane>
                        </TabContent>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </SettingsSkeleton>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SimplePage;
