import React, { useEffect, useMemo, Fragment, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Button,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { debounce } from "lodash";

import classnames from "classnames";
//redux
import { useSelector, useDispatch } from "react-redux";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import CustomWebcam from "../Webcam";
import { Status } from "../TransactionsCol";

import { getProfile, profileSuccess } from "../../../store/actions";

import { useSearchParams, useNavigate } from "react-router-dom";
import { ProgressStatus } from "./ProgressStatus";
const api = new APIClient();

const AccountVerificationModal = ({ data, isModalOpen, openModal }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const dispatch = useDispatch();

  const [currentCaptureField, setCurrentCaptureField] = useState("");

  //
  const [captureCamOpen, setCaptureCamOpen] = useState("");
  const [captureFaceID, setCaptureFaceID] = useState("");

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  // Custom Hover Tabs
  const [customHoverTab, setcustomHoverTab] = useState("1");

  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };

  useEffect(() => {
    if (user.verify_face === "pending") {
      dispatch(getProfile());
    }
  }, [user]);

  const [alert, setAlert] = useState({
    status: false,
    title: "Confirming...",
    message: "Confirming user...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      face_id: "",
      national_id: "",
    },

    validationSchema: Yup.object({
      face_id: Yup.string().required("Please capture face"),
      national_id: Yup.string().required("Please capture your valid national id"),
    }),

    onSubmit: async (values) => {
      try {
        setAlert({ status: false });

        setAlert({
          ...alert,
          status: true,
          title: "Sending...",
          message: "Sending request. please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });
        console.log(values, "VALUESS__REQUESTTS");
        setSuccessMsg("");

        setErrorMsg("");

        const data = await api.update("/account/verify-face/v2/", values);

        setSuccessMsg(data.message);
        // dispatch(getProfile());

        dispatch(profileSuccess(data.user));

        console.log(data, "REQUEST__STATS__DATAA");
        customHovertoggle("2");

        setAlert({
          ...alert,
          status: false,
        });

        setTimeout(() => {
          setSuccessMsg("");
        }, 2000);
      } catch (error) {
        setAlert({
          ...alert,
          status: false,
        });

        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        // setTimeout(() => {
        //   setErrorMsg("");
        // }, 3000);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(validation.values, "VALL__VALUES");

  console.log(validation.errors, "VALL__ERRORS");

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpen}
        role="dialog"
        autoFocus={true}
        centered
        id="topmodal"
        modalClassName="fadeInRight"
        backdrop={"static"}
      >
        <ModalHeader
          toggle={() => {
            setcustomHoverTab("1");
            openModal(!isModalOpen);
          }}
        >
          <h5 className="modal-title" id="addAddressModalLabel">
            Account Verification
          </h5>
        </ModalHeader>
        <ModalBody>
          <Col xxl={12}>
            <Card>
              <CardBody>
                <TabContent activeTab={customHoverTab} className="text-muted">
                  <TabPane tabId="1" id="custom-hover-customere">
                    <CardBody>
                      <p className="text-muted">
                        <code>Verify </code> your account to use simply services
                      </p>
                    </CardBody>
                    {/* <div className="border ">
                      <Nav pills className="custom-hover-nav-tabs justify-content-center">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: customHoverTab === "1" })}
                          >
                            <i className="ri-user-fill nav-icon nav-tab-position"></i>
                            <h5 className="nav-titl nav-tab-position m-0">User</h5>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: customHoverTab === "2" })}
                          >
                            <i className="ri-file-text-line nav-icon nav-tab-position"></i>
                            <h5 className="nav-titl nav-tab-position m-0">Confirm details</h5>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div> */}
                    <div className="table-responsive">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody>
                          <Input type="hidden" id="id-field" />
                          <Row className="g-3">
                            <div className="d-flex align-items-center gap-2">
                              <Button
                                className="btn-soft-success"
                                onClick={() => {
                                  setCurrentCaptureField("national_id");
                                  setCaptureCamOpen(!captureCamOpen);
                                }}
                              >
                                capture
                              </Button>

                              <Label className="form-label mt-1">Capture the front of your valid national ID</Label>
                            </div>
                          </Row>

                          {validation.values.national_id ? (
                            <Col className="border border-dashed border-2 border-primary  d-flex justify-content-center mt-4">
                              <img className="avatar-xl text-center" src={validation.values.national_id} alt="" />
                            </Col>
                          ) : null}
                        </ModalBody>
                        <ModalBody>
                          <Input type="hidden" id="id-field" />
                          <Row className="g-3">
                            <div className="d-flex align-items-center gap-2">
                              <Button
                                className="btn-soft-success"
                                onClick={() => {
                                  setCurrentCaptureField("face_id");
                                  setCaptureFaceID(!captureFaceID);
                                }}
                              >
                                capture
                              </Button>

                              <Label className="form-label  mt-1">Capture your facial photo</Label>
                            </div>
                          </Row>

                          {validation.values.face_id ? (
                            <Col className="border border-dashed border-2 border-primary  d-flex justify-content-center mt-4">
                              <img className="avatar-xl text-center" src={validation.values.face_id} alt="" />
                            </Col>
                          ) : null}
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-space-around">
                            <button type="button" className="btn btn-soft-dark" onClick={() => setcustomHoverTab("1")}>
                              Go Back
                            </button>

                            {!validation.isSubmitting && !isApproved && (
                              <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                <i className="ri-send-plane-2-fill label-icon align-middle fs-16 ms-2"></i>
                                Confirm
                              </button>
                            )}

                            {validation.isSubmitting && (
                              <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                                <span className="d-flex align-items-center">
                                  <span className="flex-grow-1 me-2">Loading...</span>
                                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                                    {" "}
                                    Loading...{" "}
                                  </Spinner>
                                </span>
                              </Button>
                            )}
                          </div>
                        </ModalFooter>
                        {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
                      </Form>
                    </div>
                  </TabPane>

                  {/*  */}
                  <TabPane tabId="2" id="custom-hover-customere">
                    <Row className="d-flex justify-content-center text text-white-50 text-center">
                      <Col lg={6} xs={4}>
                        <div className="p-2">
                          <Status status={user?.verify_face} />
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive">
                      <ProgressStatus
                        verification_progress={user?.verification_progress}
                        verification_status={user?.verify_face}
                      />
                    </div>
                    <ModalFooter>
                      <div className="hstack gap-2 justify-content-end">
                        {user.verify_face === "fail" ? (
                          <button
                            type="button"
                            className="btn btn-soft-dark"
                            onClick={() => {
                              setcustomHoverTab("1");
                            }}
                          >
                            Go Back
                          </button>
                        ) : (
                          ""
                        )}

                        {user.verify_face === "success" ? (
                          <button
                            onClick={() => {
                              openModal(!isModalOpen);
                              validation.resetForm();
                            }}
                            type="button"
                            className="btn btn-light"
                          >
                            Close
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </ModalFooter>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>

          <Modal isOpen={captureCamOpen || captureFaceID} role="dialog" autoFocus={true} centered id="addAddressModal">
            <ModalHeader
              toggle={() => {
                // setModal(!modal);
                setCaptureCamOpen(false);
                setCaptureFaceID(false);
              }}
            >
              <CustomWebcam validation={validation} value={currentCaptureField} />
            </ModalHeader>
          </Modal>
        </ModalBody>
      </Modal>
      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default AccountVerificationModal;
