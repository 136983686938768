import React, { useState } from "react";

import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";
import classnames from "classnames";

import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import * as Yup from "yup";
import { APIClient } from "../utils/api_helper";

import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../store/actions";

const api = new APIClient();

const SessionExpirationModal = ({ isExpired, setOpenOtpModal, reference }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding sssscard, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const validateCharge = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: "send_otp",
      reference: reference,
      otp: "",
    },

    validationSchema: Yup.object({
      reference: Yup.string().required("Please enter the account number"),
      otp: Yup.string().required("Please enter the otp code sent"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Validating...",
          message: "Validating, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/transaction/charge/verify/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        const charge_response = data?.charge_response?.data;

        // if (charge_response.status === "pay_offline") {
        setOpenOtpModal(false);
        // }

        setSuccessMsg(charge_response.display_text);

        setAlert({ ...alert, status: false });
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  console.log(validateCharge.values, "VALIDATE_CHART33");

  console.log(validateCharge.errors, "VALIDATION__ERRORS");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
      type: "",
      description: "",
      otp_code: "",
    },

    validationSchema: Yup.object({
      otp: Yup.string().required("Please enter the account number"),
      type: Yup.string().required("Please select your card type"),
      description: Yup.string().required("Please enter a description"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Proccessing...",
          message: "Adding card, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/wallets/card/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        dispatch(getCards());

        setSuccessMsg(data.message);
        setErrorMsg("");

        validation.resetForm();

        // toggleModal();

        setAlert({ ...alert, status: false });

        setOpenOtpModal(false);

        // setTimeout(() => {
        //  }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal id="topmodal" isOpen={isExpired}>
          <ModalHeader className="modal-title" id="myModalLabel"></ModalHeader>
          <ModalBody className="">
            <Col lg={12} xl={12} xxl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xxl={12} lg={12} xl={12}>
                      <CardBody>
                        <h6>
                          Your session has expired due to prolonged inactivity. <br />
                          Please login again to continue.
                        </h6>

                        <TabContent activeTab={topBorderTab} className="">
                          <TabPane tabId="1" id="nav-border-justified-profile">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                validateCharge.handleSubmit();
                              }}
                            >
                              <ModalFooter>
                                <DropdownItem href="/login" className="d-flex justify-content-end">
                                  <i className="mdi mdi-logout fs-16 me-1"></i>{" "}
                                  <span className="align-right">Login</span>
                                </DropdownItem>

                                {/* <button
                                  href="/logout"
                                  type="button"
                                  className="btn btn-success btn-label right ms-auto nexttab"
                                >
                                  Login
                                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                </button> */}
                              </ModalFooter>
                            </Form>
                          </TabPane>

                          <TabPane tabId="3" id="nav-border-justified-messages"></TabPane>
                        </TabContent>
                      </CardBody>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  );
};

export default SessionExpirationModal;
