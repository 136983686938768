import React, { useState } from "react";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Cleave from "cleave.js/react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";

import classnames from "classnames";
import SimplyTab from "./Tabs/SimplyTab";

const SendMoney = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const productState = [
    {
      options: [
        { label: "Select State...", value: "Select State" },
        { label: "Alabama", value: "Alabama" },
        { label: "Alaska", value: "Alaska" },
        { label: "American Samoa", value: "American Samoa" },
        { label: "California", value: "California" },
        { label: "Colorado", value: "Colorado" },
        { label: "District Of Columbia", value: "District Of Columbia" },
        { label: "Florida", value: "Florida" },
        { label: "Georgia", value: "Georgia" },
        { label: "Guam", value: "Guam" },
        { label: "Hawaii", value: "Hawaii" },
        { label: "Idaho", value: "Idaho" },
        { label: "Kansas", value: "Kansas" },
        { label: "Louisiana", value: "Louisiana" },
        { label: "Montana", value: "Montana" },
        { label: "Nevada", value: "Nevada" },
        { label: "New Jersey", value: "New Jersey" },
        { label: "New Mexico", value: "New Mexico" },
        { label: "New York", value: "New York" },
      ],
    },
  ];

  const productCountry = [
    {
      options: [
        { label: "Select Country...", value: "Select Country" },
        { label: "United States", value: "United States" },
      ],
    },
  ];

  document.title = "Send money | SimplyPay";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="SEND MONEY" />

          <Row>
            <Col md={12} xxl="10">
              <Card>
                <CardBody className="checkout-tab">
                  <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                    <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                      <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            { active: activeTab === 1, done: activeTab <= 4 && activeTab >= 0 },
                            "p-3"
                          )}
                          onClick={() => {
                            toggleTab(1);
                          }}
                        >
                          <i className=" ri-smartphone-line p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                          To mobile wallet
                        </NavLink>
                      </NavItem>
                      <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            { active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 },
                            "p-3"
                          )}
                          onClick={() => {
                            toggleTab(2);
                          }}
                        >
                          <i className="ri-side-bar-fill p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                          To Bank
                        </NavLink>
                      </NavItem>
                      <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            { active: activeTab === 3, done: activeTab <= 4 && activeTab > 2 },
                            "p-3"
                          )}
                          onClick={() => {
                            toggleTab(3);
                          }}
                        >
                          <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                          To Simply
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1} id="pills-bill-info">
                      <div>
                        <h5 className="mb-1">Select provider</h5>
                        <p className="text-muted mb-4">
                          Please select the provider and enter the reciepient information
                        </p>
                      </div>

                      <Row className="g-4">
                        <Col lg={3} sm={4} className="col-6">
                          <div>
                            <div className="form-check card-radio">
                              <Input
                                id="paymentMethod01"
                                name="paymentMethod"
                                type="radio"
                                className="form-check-input"
                                defaultChecked
                              />
                              <Label className="form-check-label" htmlFor="paymentMethod01">
                                <div className="providerImg">
                                  <span className="fs-16 text-muted me-2">
                                    <img src="/static/icons/mtn-logo.jpeg " alt="" />
                                  </span>
                                </div>
                                {/* <span className="fs-14 text-wrap">MTN</span> */}
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} sm={4} className="col-6">
                          <div>
                            <div className="form-check card-radio">
                              <Input
                                id="paymentMethod02"
                                name="paymentMethod"
                                type="radio"
                                className="form-check-input"
                              />
                              <Label className="form-check-label" htmlFor="paymentMethod02">
                                <div className="providerImg">
                                  <span className="fs-16 text-muted me-2">
                                    <img src="/static/icons/vodafone.png" alt="" />
                                  </span>
                                </div>
                              </Label>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3} sm={4} className="col-6">
                          <div>
                            <div className="form-check card-radio">
                              <Input
                                id="paymentMethod03"
                                name="paymentMethod"
                                type="radio"
                                className="form-check-input"
                              />
                              <Label className="form-check-label" htmlFor="paymentMethod03">
                                <div className="providerImg">
                                  <span className="fs-16 text-muted me-2">
                                    {/* <i className="ri-money-dollar-box-fill align-bottom"></i> */}
                                    <img src="/static/icons/at.jpeg" alt="" />
                                  </span>
                                </div>
                                {/* <span className="fs-14 text-wrap">AIRTEL/TIGO</span> */}
                              </Label>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3} sm={4} className="col-6">
                          <div>
                            <div className="form-check card-radio">
                              <Input
                                id="paymentMethod04"
                                name="paymentMethod"
                                type="radio"
                                className="form-check-input"
                              />
                              <Label className="form-check-label" htmlFor="paymentMethod04">
                                <div className="providerImg">
                                  <span className="fs-16 text-muted me-2">
                                    {/* <i className="ri-money-dollar-box-fill align-bottom"></i> */}
                                    <img src="/static/icons/glo.jpeg" alt="" />
                                  </span>
                                </div>
                                {/* <span className="fs-14 text-wrap">AIRTEL/TIGO</span> */}
                              </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="collapse show" id="paymentmethodCollapse">
                        <Card className="p-4 border shadow-none mb-0 mt-4">
                          <Row className="gy-3">
                            <Col xl={6}>
                              <div className="mb-0">
                                <label htmlFor="cleave-numeral" className="form-label">
                                  Amount
                                </label>

                                <Cleave
                                  placeholder="Enter amount to send"
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: "thousand",
                                  }}
                                  value={""}
                                  // onChange={(e) => onNumberChange(e)}
                                  className="form-control"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <Label htmlFor="cc-name" className="form-label">
                                Phone number
                              </Label>
                              <div className="input-group mb-3">
                                <span className="input-group-text">#</span>

                                <Input
                                  type="text"
                                  className="form-control"
                                  aria-label="Dollar amount (with dot and two decimal places)"
                                  placeholder="Recipeint phone number"
                                />
                              </div>
                              <small className="text-muted">make sure to verify the number</small>
                            </Col>

                            <Col md={6}>
                              <Label htmlFor="cc-name" className="form-label">
                                Recipient name
                              </Label>
                              <Input type="text" className="form-control" id="cc-name" placeholder="Enter name" />
                            </Col>

                            <Col md={6}>
                              <Label htmlFor="cc-name" className="form-label">
                                Naration
                              </Label>
                              <Input type="text" className="form-control" id="cc-name" placeholder="enter a naration" />
                            </Col>
                          </Row>
                        </Card>
                        <div className="text-muted mt-2 fst-italic">
                          <i data-feather="lock" className="text-muted icon-xs"></i> Your transaction is secured with
                          SSL encryption
                        </div>
                      </div>

                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-success btn-label right ms-auto nexttab"
                          onClick={() => {
                            console.log("CONFIRMING SEND");
                          }}
                        >
                          <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                          Proceed
                        </button>
                      </div>
                    </TabPane>

                    <TabPane tabId={2}>
                      <div>
                        <h5 className="mb-1">Select destination</h5>
                        <p className="text-muted mb-4">Please select and the reciepient information</p>
                      </div>

                      <Row className="g-4">
                        <Col lg={4} sm={6}>
                          <div>
                            <div className="form-check card-radio">
                              <Input
                                id="paymentMethod01"
                                name="paymentMethod"
                                type="radio"
                                className="form-check-input"
                              />
                              <Label className="form-check-label" htmlFor="paymentMethod01">
                                <span className="fs-16 text-muted me-2">
                                  <i className="ri-paypal-fill align-bottom"></i>
                                </span>
                                <span className="fs-14 text-wrap text-muted text-uppercase fw-semibold">Local</span>
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} sm={6}>
                          <div>
                            <div className="form-check card-radio">
                              <Input
                                id="paymentMethod02"
                                name="paymentMethod"
                                type="radio"
                                className="form-check-input"
                              />
                              <Label className="form-check-label" htmlFor="paymentMethod02">
                                <span className="fs-16 text-muted me-2">
                                  <i className="ri-bank-card-fill align-bottom"></i>
                                </span>
                                <span className="fs-14 text-wrap text-muted text-uppercase fw-semibold">
                                  International
                                </span>
                              </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="collapse show" id="paymentmethodCollapse">
                        <Card className="p-4 border shadow-none mb-0 mt-4">
                          <Row className="gy-3">
                            <Col xl={6}>
                              <div className="mb-3">
                                <Label for="ForminputState" className="form-label">
                                  Select bank
                                </Label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  data-choices
                                  data-choices-sorting="true"
                                >
                                  <option>Choose...</option>
                                  <option>...</option>
                                </select>
                              </div>
                            </Col>
                            <Col md={6}>
                              <Label htmlFor="cc-name" className="form-label">
                                Amount
                              </Label>
                              <div className="input-group mb-3">
                                <span className="input-group-text">#</span>

                                <Input
                                  type="text"
                                  className="form-control bg-light"
                                  aria-label="Dollar amount (with dot and two decimal places)"
                                  placeholder="0.00"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <Label htmlFor="cc-number" className="form-label">
                                Account number
                              </Label>
                              <Input
                                type="text"
                                className="form-control bg-light"
                                id="cc-number"
                                placeholder="xxxx xxxx xxxx xxxx"
                              />
                            </Col>

                            <Col md={6}>
                              <Label htmlFor="cc-name" className="form-label">
                                Recipient name
                              </Label>
                              <Input type="text" className="form-control bg-light" id="cc-name" placeholder="Jon Doe" />
                              {/* <small className="text-muted">Full name as displayed on card</small> */}
                            </Col>
                          </Row>
                        </Card>
                        <div className="text-muted mt-2 fst-italic">
                          <i data-feather="lock" className="text-muted icon-xs"></i> Your transaction is secured with
                          SSL encryption
                        </div>
                      </div>

                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-success btn-label right ms-auto nexttab"
                          onClick={() => {
                            // toggleTab(activeTab + 1);
                          }}
                        >
                          <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                          Proceed
                        </button>
                      </div>
                    </TabPane>

                    <TabPane tabId={3}>
                      <div>
                        <h5 className="mb-1">Send money to Simply user</h5>
                        <p className="text-muted mb-4">Please enter recipient information below</p>
                      </div>

                      <SimplyTab />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* modal Delete Address */}
      <Modal
        isOpen={deletemodal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={toggledeletemodal}
      >
        <ModalHeader
          toggle={() => {
            setDeleteModal(!deletemodal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Address ?</p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* modal Add Address */}
      <Modal isOpen={modal} role="dialog" autoFocus={true} centered id="addAddressModal" toggle={togglemodal}>
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          <h5 className="modal-title" id="addAddressModalLabel">
            Address
          </h5>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="mb-3">
              <Label for="addaddress-Name" className="form-label">
                Name
              </Label>
              <Input type="text" className="form-control" id="addaddress-Name" placeholder="Enter Name" />
            </div>

            <div className="mb-3">
              <Label for="addaddress-textarea" className="form-label">
                Address
              </Label>
              <textarea
                className="form-control"
                id="addaddress-textarea"
                placeholder="Enter Address"
                rows="2"
              ></textarea>
            </div>

            <div className="mb-3">
              <Label for="addaddress-Name" className="form-label">
                Phone
              </Label>
              <Input type="text" className="form-control" id="addaddress-Name" placeholder="Enter Phone No." />
            </div>

            <div className="mb-3">
              <Label for="state" className="form-label">
                Address Type
              </Label>
              <select className="form-select" id="state" data-plugin="choices">
                <option value="homeAddress">Home (7am to 10pm)</option>
                <option value="officeAddress">Office (11am to 7pm)</option>
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SendMoney;
