export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG";

// Get Organisations
export const GET_USER_ORG = "GET_USER_ORG";
export const GET_USER_ORG_SUCCESS = "GET_USER_ORG_SUCCESS";
export const GET_USER_ORG_FAILURE = "GET_USER_ORG_FAILURE";
