import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/RequestsTableContainr";

import { TransactionID, Status } from "./TransactionsCol";
import { getFees } from "../../store/actions";
import ListLoader from "../../Components/modules/ListLoader";
import EmptyList from "../../Components/EmptyList";

import { APIClient } from "../../utils/api_helper";

import useSWR from "swr";

import { useSearchParams } from "react-router-dom";
import LoanModal from "./Modals/LoanModal";
import RefreshButton from "../../Components/Loaders/RefreshButton";

const api = new APIClient();

const loanListFetcher = async (url) => {
  const data = await api.get(url);

  return data;
};

const AllLoans = () => {
  // Access a specific query parameter
  // const type = query.get("type") || "";

  //
  let { data: loanList, error: transactionList_error, isValidating, mutate } = useSWR(`/loan/`, loanListFetcher);

  console.log(loanList, "loanListloanListloanList");

  const [openLoanModal, setOpenLoanModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Amount",
        filterable: true,
        Cell: (cellProps) => {
          const amount = cellProps.row.original?.amount;
          return (
            <React.Fragment>
              <span className="">GHS {amount}</span>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Total repayment amount",
        filterable: true,
        Cell: (cellProps) => {
          const total_repayment_amount = cellProps.row.original?.total_repayment_amount;
          return (
            <React.Fragment>
              <span className="">GHS {total_repayment_amount}</span>
            </React.Fragment>
          );
        },
      },
      // {
      //   Header: "Total repayment amount",
      //   accessor: "total_repayment_amount",
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return <TransactionID {...cellProps} />;
      //   },
      // },
      {
        Header: "type",
        accessor: "type",
        filterable: false,
      },
      {
        Header: "Interest rate",
        filterable: true,
        Cell: (cellProps) => {
          const interest_rate = cellProps.row.original?.interest_rate;
          return (
            <React.Fragment>
              <span className="">{interest_rate} %</span>
            </React.Fragment>
          );
        },
      },

      // {
      //   Header: "created at",
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     const created_at_time = cellProps.row.original?.created_at_time;
      //     return (
      //       <React.Fragment>
      //         <span className="">{created_at_time}</span>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "requested at",
        filterable: true,
        Cell: (cellProps) => {
          let dateTime = cellProps.row.original?.created_at_time;

          let date = dateTime?.split("T")[0];
          let time = dateTime?.split("T")[1]?.split(".")[0];

          return (
            <React.Fragment>
              <span className="">
                {date}, {time}
              </span>
            </React.Fragment>
          );
        },
      },

      {
        Header: "due date",
        filterable: true,
        Cell: (cellProps) => {
          let due_date_arrow = cellProps.row.original?.due_date_arrow;

          // let date = dateTime?.split("T")[0];
          // let time = dateTime?.split("T")[1]?.split(".")[0];

          return (
            <React.Fragment>
              <span className="">
                {/* {date}, {time} */}
                {due_date_arrow}
              </span>
            </React.Fragment>
          );
        },
      },

      // {
      //   Header: "Time",
      //   Cell: (transaction) => (
      //     <>
      //       <small className="text-muted">{transaction.row.original.created_at_arrow}</small>
      //     </>
      //   ),
      // },

      {
        Header: "Source",
        accessor: "payment_method",
        filterable: false,
        Cell: (cellProps) => {
          return <TransactionID {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status status={cellProps.row.original.status} />;
        },
      },

      {
        Header: "Action",
        Cell: (cell) => {
          const id = cell.row.original.uuid;
          return (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`/fee/${id}`} className="d-inline-block auth-logo">
                  <DropdownItem>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i> View
                  </DropdownItem>
                </Link>

                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <Row className="align-items-center mb-4 g-3">
        <div className="col-sm-auto ms-auto col-auto">
          <div className="d-flex gap-2">
            <Link
              onClick={() => setOpenLoanModal(!openLoanModal)}
              // to="/pay-fees"
              data-bs-toggle="modal"
              className="btn btn-primary"
            >
              Apply for loan
            </Link>
          </div>
        </div>
      </Row>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">Loan history</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <RefreshButton isValidating={isValidating} mutate={mutate} />

                {/* <div className="search-box">
                  <input type="text" className="form-control search" placeholder="Search by reference..." />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <button className="btn btn-success">
                  <i className="ri-equalizer-line align-bottom me-1"></i>
                  Filters
                </button> */}
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            columns={columns}
            data={loanList?.data || []}
            isGlobalFilter={false}
            isAddUserList={false}
            customPageSize={8}
            className="custom-header-css"
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
            thClass="table-light text-muted"
            customFetchData={() => console.log("yesss")}
          />
        </CardBody>
        {isValidating ? <ListLoader /> : null}

        {!isValidating && !loanList?.data ? <EmptyList text="No transactions found yet!" /> : null}
      </Card>

      <LoanModal openModal={openLoanModal} setOpenModal={setOpenLoanModal} />
    </React.Fragment>
  );
};

export default AllLoans;
