import { APIClient } from "../utils/api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session storage
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

let user = getLoggedInUser();
let token = "";
if (user) {
  token = user.data.access_token;
}

//is user is logged in
export const isUserAuthenticated = () => getLoggedInUser() !== null;

// Auth URL Methods
export const postRegister = (data) => api.create(url.REGISTER, data);
export const postLogin = (data) => api.create(url.LOGIN, data);
export const postForgotPassword = (data) => api.create(url.FORGORT_PASSWORD, data);
export const postResetPassword = (params, data) =>
  api.create(
    url.RESET_PASSWORD.replace(/uid|token/g, (m) => params[m]),
    data
  );
export const postGoogleAuth = (data) => api.create(url.GOOGLE_CREATE, data);

// Cases URL Methods
export const getCases = (params) => api.get(url.GET_CASES_LIST, params);
export const addCases = (data) => api.create(url.CREATE_CASES, data);
export const deleteCasesAttachment = (attachmentID) =>
  api.delete(url.DELETE_CASES_ATTACHMENT.replace("{id}", attachmentID));
export const updateCasesComment = (commentID, data) =>
  api.update(url.UPDATE_CASES_COMMENT.replace("{id}", commentID), data);
export const deleteCasesComment = (commentID) => api.delete(url.DELETE_CASES_COMMENT.replace("{id}", commentID));
export const getCase = (caseID) => api.get(url.GET_CASE.replace("{id}", caseID));
export const addCase = (caseID, data) => api.create(url.CREATE_CASE.replace("{id}", caseID), data);
export const updateCase = (caseID, data) => api.update(url.UPDATE_CASE.replace("{id}", caseID), data);
export const deleteCase = (caseID) => api.delete(url.DELETE_CASE.replace("{id}", caseID));
export const getClientCases = (id, params) => api.get(url.GET_CLIENT_CASES.replace("{id}", id), params);

// Donor Contacts URL Methods
export const getContacts = (params) => api.get(url.GET_CONTACTS_LIST, params);
export const createContacts = (data) => api.create(url.CREATE_CONTACTS, data);
export const deleteContactAttahment = (attachmentID) =>
  api.delete(url.DELETE_CONTACT_ATTACHMENT.replace("{id}", attachmentID));
export const updateContactComment = (commentID, data) =>
  api.update(url.UPDATE_CONTACT_COMMENT.replace("{id}", commentID), data);
export const deleteContactComment = (commentID) => api.delete(url.DELETE_CONTACT_COMMENT.replace("{id}", commentID));
export const getContact = (id) => api.get(url.GET_CONTACT.replace("{id}", id));
export const createContact = (id, data) => api.create(url.CREATE_CONTACT.replace("{id}", id), data);
export const updateContact = (id, data) => api.update(url.UPDATE_CONTACT.replace("{id}", id), data);
export const deleteContact = (id) => api.delete(url.DELETE_CONTACT.replace("{id}", id));
// Donor Leads URL Methods
export const addNewLead = (data) => api.create(url.CREATE_LEADS, data);
export const postLeads = (data) => api.create(url.CREATE_LEADS, data);
export const getLeads = (params) => api.get(url.GET_LEADS_LIST, params);
export const deleteLead = (leadID) => api.delete(url.DELETE_LEAD.replace("{id}", leadID));
export const updateLead = (id, data) => api.update(url.UPDATE_LEAD.replace("{id}", id), data);

// Donor orgs/account
export const createOrganization = (data) => api.create(url.CREATE_ACCOUNTS, data);
export const getOrganizationList = (params) => api.get(url.GET_ACCOUNTS_LIST, params);
export const deleteOrganization = (accID) => api.delete(url.DELETE_ACCOUNT.replace("{id}", accID));
export const updateOrganization = (id, data) => api.update(url.UPDATE_ACCOUNT.replace("{id}", id), data);

// Donor donations
export const createDonation = (data) => api.create(url.CREATE_DONATION, data);
export const getDonationList = (params) => api.get(url.GET_DONATION_LIST, params);
export const deleteDonation = (id) => api.delete(url.DELETE_DONATION.replace("{id}", id));
export const updateDonation = (id, data) => api.update(url.UPDATE_DONATION.replace("{id}", id), data);

// Organisation URL Methods
export const getOrganisationList = () => api.get(url.GET_ORG_LIST);
// export const createOrganisations = (data) => api.create(url.CREATE_ORG, data);
export const postOrganisations = (data) => api.create(url.CREATE_ORG, data);
export const updateOrg = (id, data) => api.update(url.UPDATE_ORG.replace("{id}", id), data);
export const updateOrgAddress = (id, data) => api.update(url.UPDATE_ORG_ADDRESS.replace("{id}", id), data);
export const createOrgAddress = (data) => api.create(url.CREATE_ORG_ADDRESS, data);

// Users URL methods
export const getUsers = (params) => api.get(url.GET_USERS_LIST, params);
export const createUser = (data) => api.create(url.CREATE_USERS, data);
export const getTeamsAndUsers = () => api.get(url.GET_TEAMS_AND_USERS_LIST);
export const getUser = (id) => api.get(url.GET_USER.replace("{id}"));
export const updateUser = (id, data) => api.update(url.UPDATE_USER.replace("{id}", id), data);
export const deleteUser = (id) => api.delete(url.DELETE_USER.replace("{id}", id));
export const updateUserStatus = (id, data) => api.update(url.UPDATE_USER_STATUS.replace("{id}", id), data);

// Clients URL Methods
export const getClients = (params) => api.get(url.GET_CLIENTS_LIST, params);
export const createClients = (data) => api.create(url.CREATE_CLIENTS, data);
export const deleteClientAttahment = (attachmentID) =>
  api.delete(url.DELETE_CLIENT_ATTACHMENT.replace("{id}", attachmentID));
export const updateClientComment = (commentID, data) =>
  api.update(url.UPDATE_CLIENT_COMMENT.replace("{id}", commentID), data);
export const deleteClientComment = (commentID) => api.delete(url.DELETE_CLIENT_COMMENT.replace("{id}", commentID));
export const getClient = (id) => api.get(url.GET_CLIENT.replace("{id}", id));
export const createClient = (id, data) => api.create(url.CREATE_CLIENT.replace("{id}", id), data);
export const updateClient = (id, data) => api.update(url.UPDATE_CLIENT.replace("{id}", id), data);
export const deleteClient = (id) => api.delete(url.DELETE_CLIENT.replace("{id}", id));
export const getClientDetails = (id) => api.get(url.GET_CLIENT_DETAILS.replace("{id}", id));

// reports
export const filterReports = (params) => api.get(url.FILTER_REPORTS, params);
export const getReportsList = (params) => api.get(url.GET_REPORTS_LIST, params);
export const createReport = (data) => api.create(url.CREATE_REPORTS, data);
export const updateReport = (id, data) => api.update(url.UPDATE_REPORT.replace("{id}", id), data);
export const deleteReport = (id) => api.delete(url.DELETE_REPORT.replace("{id}", id));
// reports export
export const exportReport = (params) => api.get(url.EXPORT_REPORT, params);

// Teams URL Methods
export const getTeams = (params) => api.get(url.GET_TEAMS_LIST, params);
export const getTeam = (id) => api.get(url.GET_TEAM.replace("{id}", id));
export const createTeam = (data) => api.create(url.CREATE_TEAMS, data);
export const updateTeam = (id, data) => api.update(url.UPDATE_TEAM.replace("{id}", id), data);
export const deleteTeam = (id) => api.delete(url.DELETE_TEAM.replace("{id}", id));

// Notes URL Methods
export const getNotes = (params) => api.get(url.GET_NOTES_LIST, params);
export const getNote = (id) => api.get(url.GET_NOTE.replace("{id}", id));
export const createNotes = (data) => api.create(url.CREATE_NOTES, data);
export const updateNote = (id, data) => api.update(url.UPDATE_NOTE.replace("{id}", id), data);
export const updateNoteComment = (id, data) => api.update(url.UPDATE_NOTE_COMMENT.replace("{id}", id), data);
export const deleteNoteComment = (id) => api.update(url.DELETE_NOTE_COMMENT.replace("{id}", id));
export const deleteNote = (id) => api.delete(url.DELETE_NOTE.replace("{id}", id));
export const deleteNoteAttachment = (id) => api.delete(url.DELETE_NOTE_ATTACHMENT.replace("{id}"));
export const getClientNotes = (id, params) => api.get(url.GET_CLIENT_NOTES.replace("{id}", id), params);

// Service URL Methods
export const getServices = (params) => api.get(url.GET_SERVICES_LIST, params);
export const getService = (id) => api.get(url.GET_SERVICE.replace("{id}", id));
export const createService = (data) => api.create(url.CREATE_SERVICE, data);
export const updateService = (id, data) => api.update(url.UPDATE_SERVICE.replace("{id}", id), data);
export const deleteService = (id) => api.delete(url.DELETE_SERVICE.replace("{id}", id));

// ACTIVATE USER
export const activateUser = (url, data) => api.get(url, data);

// Tasks URL Methods
export const getTasks = (params) => api.get(url.GET_TASKS_LIST, params);
export const getTask = (id) => api.get(url.GET_TASK.replace("{id}", id));
export const createTasks = (data) => api.create(url.CREATE_TASKS, data);
export const updateTask = (id, data) => api.update(url.UPDATE_TASK.replace("{id}", id), data);
export const updateTaskComment = (id, data) => api.update(url.UPDATE_TASK_COMMENT.replace("{id}", id), data);
export const deleteTaskComment = (id) => api.update(url.DELETE_TASK_COMMENT.replace("{id}", id));
export const deleteTask = (id) => api.delete(url.DELETE_TASK.replace("{id}", id));
export const deleteTaskAttachment = (id) => api.delete(url.DELETE_TASK_ATTACHMENT.replace("{id}"));

//  URL Methods
export const getEvents = () => api.get(url.GET_EVENT_LIST);
export const getEventsIn = (start, end) =>
  api.get(url.GET_EVENT_LIST_IN_RANGE.replace("{start}", start).replace("{end}", end));
export const getEvent = (id) => api.get(url.GET_EVENT.replace("{id}", id));
export const getCategories = (id) => api.get(url.GET_EVENT.replace("{id}", id));
export const getUpCommingEvent = (id) => api.get(url.GET_EVENT.replace("{id}", id));
export const createEvents = (data) => api.create(url.CREATE_EVENTS, data);
export const updateEvent = (id, data) => api.update(url.UPDATE_EVENT.replace("{id}", id), data);
export const updateEventComment = (id, data) => api.update(url.UPDATE_EVENT_COMMENT.replace("{id}", id), data);
export const deleteEventComment = (id) => api.update(url.DELETE_EVENT_COMMENT.replace("{id}", id));
export const deleteEvent = (id) => api.delete(url.DELETE_EVENT.replace("{id}", id));
export const deleteEventAttachment = (id) => api.delete(url.DELETE_EVENT_ATTACHMENT.replace("{id}"));

// Profile Methods
export const getProfile = () => api.get(url.GET_PROFILE);

export const getCards = (data) => api.get(url.GET_CARDS, data);

export const updateProfile = (id, user) => api.update(url.UPDATE_PROFILE.replace("{id}", id), user);
export const profileChangePassword = (passwords) => api.create(url.PROFILE_CHANGE_PASSWORD, passwords);

// Billing

export const getFees = (data) => api.get(url.GET_FEES, data);

export const logOutUser = () => api.post(url.LOGOUT_USER);

export const confirmPayment = (data) => api.create(url.CONFIRM_PAYMENT_METHOD, data);

export const getBilling = () => api.get(url.GET_BILLING);
export const deleteBilling = (accID) => api.delete(url.DELETE_BILLING.replace("{id}", accID));
export const updateBilling = (id, data) => api.update(url.UPDATE_BILLING.replace("{id}", id), data);

export const getAnalytics = (data) => api.create(url.GET_ANALYTICS, data);
export const getAdminUsersList = (params) => api.get(url.GET_USERS_LIST, params);
export const getAdminOrganizationList = (params) => api.get(url.GET_ORGANIZATIONS_LIST, params);
export const getOrganizationDetails = (id) => api.get(url.GET_ORGANIZATION_DETAIL.replace("{id}", id));
export const getAdminOrganization = (id) => api.get(url.GET_ADMIN_ORGANIZATION.replace("{id}", id));
