import React from "react";

const FromCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const ToCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const DetailsCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const TransactionID = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const TypeCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Status = ({ status }) => {
  console.log(status, "STAAAAAAAA");

  return (
    <React.Fragment>
      <React.Fragment>
        {status === "successful" ? (
          <span className="badge badge-soft-success text-uppercase">{status}</span>
        ) : status === "pending" ? (
          <span className="badge badge-soft-warning text-uppercase">{status}</span>
        ) : status === "rejected" ? (
          <span className="badge badge-soft-danger text-uppercase">{status}</span>
        ) : status === "cancelled" || status === "fail" ? (
          <span className="badge badge-soft-danger text-uppercase">{status}</span>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

export { FromCol, ToCol, DetailsCol, TransactionID, TypeCol, Status };
