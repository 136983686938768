import { Col, Modal, ModalBody, Row } from "reactstrap";

import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ProccessingLoader({ setAlert, alert }) {
  // code
  return (
    <Modal
      isOpen={alert.status}
      centered
      toggle={alert.back_url ? () => null : () => setAlert({ ...alert, status: !alert.status })}
    >
      <ModalBody>
        {alert?.icon ? (
          <Row className="d-flex justify-content-center">
            {alert?.icon}
            {/* <Col md="12" className="d-flex justify-content-center">
              <lord-icon
                src="https://cdn.lordicon.com/tdrtiskw.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#405189"
                style={{ width: "130px", height: "130px" }}
              ></lord-icon>
            </Col> */}
          </Row>
        ) : (
          ""
        )}

        <div className="text-center pt-4 pb-2">
          {alert.class ? (
            <div className="mb-4">
              <div className="avatar-lg mx-auto my-2">
                <div className={`avatar-title bg-light ${alert.textClass} display-1 rounded-circle`}>
                  <span className="d-flex align-items-center">
                    <span className={`${alert.class} flex-shrink-0`} role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          <h5>{alert.title}</h5>
          <p className="text-muted">{alert.message}</p>
          <Link to={`${alert.goto_url}`} className="d-inline-block auth-logo">
            <button
              hidden={!alert.goto_url}
              className="btn btn-soft-primary mt-2"
              // onClick={() => (window.location = alert.goto_url)}
            >
              View transaction
            </button>
          </Link>

          <button
            hidden={!alert.back_url}
            className="btn btn-soft-success mt-2"
            onClick={() => setAlert({ ...alert, status: !alert.status })}
          >
            {" "}
            Perform another deposit
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
