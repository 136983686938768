import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { useLocation } from "react-router-dom";
import { useSearchParams, useNavigate } from "react-router-dom";
// Define a default UI for filtering
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={4}>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
};

const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customTotalSize,
  customFetchData,
  tableClass,
  theadClass,
  thClass,
  divClass,
  pagination,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, totalSize },
    dispatch,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualPagination: true,
      autoResetPage: false,
      pageCount: Math.ceil(customTotalSize / customPageSize),
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        totalSize: customTotalSize,
        sortBy: [
          {
            // id: "created_at",
            // desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);

  const [limit, setLimit] = useState(5);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  useEffect(() => {
    // prop function to fetch next input data
    customFetchData({ pageSize, pageIndex });
  }, [pageSize, pageIndex, customFetchData]);

  const updateQueryParam = (newPageNumber) => {
    if (newPageNumber < 0) return;

    if (limit * newPageNumber > customPageSize) return;

    setPageNumber(newPageNumber);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("offset", 5 * newPageNumber);
    newSearchParams.set("limit", 5);

    // Replace the current URL with the updated query parameters
    // history.replace({ search: newSearchParams.toString() });
    navigate({ search: newSearchParams.toString() });
  };

  return (
    <Fragment>
      <p className="text-muted">
        {totalSize ? (
          <p>
            Total <code>{totalSize}</code> records
          </p>
        ) : null}
      </p>

      {isGlobalSearch ||
        isGlobalFilter ||
        isAddOptions ||
        isAddUserList ||
        (isAddCustList && (
          <Row className="mb-2">
            {isGlobalSearch && (
              <Col md={1}>
                <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}
            {isAddOptions && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleOrderClicks}>
                    <i className="mdi mdi-plus me-1" />
                    Add New Order
                  </Button>
                </div>
              </Col>
            )}
            {isAddUserList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleUserClick}>
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New User
                  </Button>
                </div>
              </Col>
            )}
            {isAddCustList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button type="button" color="success" className="btn-rounded mb-2 me-2" onClick={handleCustomerClick}>
                    <i className="mdi mdi-plus me-1" />
                    New Customers
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        ))}

      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.reverse().map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {pagination ? (
        <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary cursor-pointer"
                onClick={(e) => updateQueryParam(pageNumber - 1)}
                // disabled={!canPreviousPage}

                disabled={pageNumber < 1}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {/* {pageIndex + 1} of {pageOptions.length} */}
              {/* {pageIndex + 1} of {Math.ceil(totalSize / pageSize)} */}
              {+pageNumber + 1} of {Math.ceil(customPageSize / 5) || ""}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              // defaultValue={pageIndex + 1}
              defaultValue={pageNumber}
              // onChange={onChangeInInput}
              onChange={(e) => updateQueryParam(+e.target.value)}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary cursor-pointer"
                onClick={(e) => updateQueryParam(pageNumber + 1)}
                disabled={limit * pageNumber >= customPageSize}
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
