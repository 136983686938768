import React, { useState } from "react";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../utils/api_helper";
import MsgToast from "../../Components/Common/MsgToast";
import TextField from "../../Components/Common/Fields/TextField";
import ProccessingLoader from "../../Components/Loaders/ProccessingLoader";

import useSWR from "swr";

const api = new APIClient();

const fetch_school = async (url) => {
  const { data } = await api.get(url);

  return data;
};
const statusOptions = [
  { label: "100L", value: "100L" },
  { label: "200L", value: "200L" },
  { label: "300L", value: "300L" },
  { label: "400L", value: "400L" },
  { label: "500L", value: "500L" },
  { label: "600L", value: "600L" },
  { label: "700L", value: "700L" },
];

const universityOptions = [
  { label: "KNUST", value: "KNUST" },
  { label: "WINNEBA", value: "WINNEBA" },
  { label: "UCC", value: "UCC" },
  { label: "UDS", value: "UDS" },
  { label: "UENR", value: "UENR" },
  { label: "WOG-LEGON", value: "WOG-LEGON" },
];

const academicYearOptions = [
  { label: "First semester", value: "First semester" },
  { label: "Second Semester", value: "Second Semester" },
  { label: "Thid Semester", value: "Third Semester" },
];

const PaySchoolFees = () => {
  // Border Top Nav
  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing fee...",
    message: "Proccessing please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-success",
  });

  const [activeVerticalTab, setactiveVerticalTab] = useState(7);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState(false);

  function toggleVerticalTab(tab) {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];

      if (tab >= 7 && tab <= 11) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  }

  let { data, error, isValidating, mutate: mutateStatistics } = useSWR(`/school/`, fetch_school, {});

  console.log(data, "DTAAAAAA");
  const schoolOptions = data?.map((sch) => {
    return { value: sch.reference, label: sch.name };
  });
  console.log(schoolOptions, "SCHOOLL__OPTIONNSS");

  const studentValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: "",
      academicYear: "",
      university: "",
      currentLevel: "",
      amount: "",
      studentId: "",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter the student full name"),
      academicYear: Yup.string().required("Please select the academic year"),
      university: Yup.string().required("Please select the university"),
      currentLevel: Yup.string().required("Please select current Level"),
      amount: Yup.string().required("Please enter the amount to be paid"),
      studentId: Yup.string().required("Please Enter the student ID"),
    }),

    onSubmit: async (values) => {
      console.log("CREATING FEES");
      toggleVerticalTab(activeVerticalTab + 1);
    },
  });

  console.log(studentValidation.errors, "STUDENT_ERRORS");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: studentValidation.values.fullName,
      academicYear: studentValidation.values.academicYear,
      university: studentValidation.values.university,
      currentLevel: studentValidation.values.currentLevel,
      amount: studentValidation.values.amount,
      studentId: studentValidation.values.studentId,
      accountNumber: "",
      accountName: "",
      paymentMethod: "",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter the student full name"),
      academicYear: Yup.string().required("Please select the academic year"),
      university: Yup.string().required("Please select the university"),
      currentLevel: Yup.string().required("Please select current Level"),
      amount: Yup.string().required("Please enter the amount to be paid"),
      studentId: Yup.string().required("Please Enter the student ID"),
      // bank: Yup.string().required("Please Enter the bank"),
      // accountName: Yup.string().notRequiredr("Please Enter the account name"),
    }),

    onSubmit: async (values) => {
      console.log("CREATING FEES");
      setAlert({
        status: true,
        title: "Proccessing fee...",
        message: "Proccessing please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-success",
      });

      setErrorMsg("");
      try {
        const data = await api.post("/school/fees/", values);

        console.log(data, "FEESS_DSATA");

        setSuccessMsg(data.message);
        setAlert({ ...alert, status: false });

        // setTimeout(() => {
        //   history.push("/");
        // }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(validation.values, "VALUEEESS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="PAY SCHOOL FEES" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">PAY YOUR SCHOOL FEES</h4>
                </CardHeader>
                <CardBody className="form-steps">
                  <div className="vertical-navs-step">
                    <Row className="gy-5">
                      <Col lg={3}>
                        <Nav className="flex-column custom-nav nav-pills">
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeVerticalTab === 7,
                                done: activeVerticalTab <= 11 && activeVerticalTab > 7,
                              })}
                              onClick={() => {
                                toggleVerticalTab(7);
                              }}
                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                Step 1
                              </span>
                              Student information
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeVerticalTab === 8,
                                done: activeVerticalTab <= 11 && activeVerticalTab >= 8,
                              })}
                              onClick={() => {
                                // toggleVerticalTab(8);
                              }}
                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                Step 2
                              </span>
                              Payment
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>

                      <Col lg={6}>
                        <div className="px-lg-4">
                          <TabContent activeTab={activeVerticalTab}>
                            <TabPane tabId={7}>
                              <div>
                                <h5>Student information</h5>
                                <p className="text-muted">Fill all information below</p>
                              </div>

                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  studentValidation.handleSubmit();
                                  return false;
                                }}
                              >
                                <div>
                                  <Row className="g-3">
                                    <TextField
                                      md="6"
                                      mb="mb-3"
                                      value={studentValidation.values.fullName || ""}
                                      onChange={studentValidation.handleChange}
                                      validation={studentValidation}
                                      onBlur={studentValidation.handleBlur}
                                      placeholder="Enter the student full name"
                                      label="Full name"
                                      name="fullName"
                                    />

                                    <TextField
                                      md="6"
                                      mb="mb-3"
                                      value={studentValidation.values.studentId || ""}
                                      onChange={studentValidation.handleChange}
                                      validation={studentValidation}
                                      onBlur={studentValidation.handleBlur}
                                      placeholder="Enter the student ID"
                                      label="Student ID"
                                      name="studentId"
                                    />

                                    <Col sm={6}>
                                      <Label>Choose Your University</Label>
                                      <Select
                                        md="12"
                                        // onChange={filterstudentValidation.handleChange}
                                        isClearable
                                        onChange={(e) => studentValidation.setFieldValue("university", e.value)}
                                        // onBlur={filterstudentValidation.handleBlur}
                                        placeholder="Select a univeristy..."
                                        name="university"
                                        optional={true}
                                        options={schoolOptions}
                                        // value={filterstudentValidation.values.status || ""}
                                        // value={{
                                        //   value: filterstudentValidation.values.status,
                                        //   label: filterstudentValidation.values.status || "Select status",
                                        // }}
                                      />
                                    </Col>
                                    <Col sm={6}>
                                      <Label>Select your current level</Label>
                                      <Select
                                        md="12"
                                        isClearable
                                        onChange={(e) => studentValidation.setFieldValue("currentLevel", e.value)}
                                        placeholder="Select a current level..."
                                        name="currentLevel"
                                        optional={true}
                                        options={statusOptions}
                                      />
                                    </Col>
                                    <Col sm={6}>
                                      <Label>Choose your academic year</Label>
                                      <Select
                                        md="12"
                                        isClearable
                                        onChange={(e) => studentValidation.setFieldValue("academicYear", e.value)}
                                        placeholder="Select a aca. year..."
                                        name="academicYear"
                                        optional={true}
                                        options={academicYearOptions}
                                      />
                                    </Col>
                                    <Col sm={6}>
                                      <Label>Payable amount</Label>
                                      <div className="input-group mb-3 barge barge-soft-primary">
                                        <span className="input-group-text mx-1">GHS</span>
                                        {/* <span className="input-group-text">0.00</span> */}
                                        <Input
                                          type="text"
                                          className="form-control rounded-pill"
                                          aria-label="Dollar amount (with dot and two decimal places)"
                                          onChange={studentValidation.handleChange}
                                          value={studentValidation.values.amount}
                                          name={"amount"}
                                          placeholder="0.00"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="d-flex align-items-start gap-3 mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                    // onClick={() => {
                                    //   if (Object.keys(studentValidation.errors) > 0) {
                                    //     return;
                                    //   }

                                    //   toggleVerticalTab(activeVerticalTab + 1);
                                    // }}
                                  >
                                    <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                    Proceed
                                  </button>
                                </div>
                              </Form>
                            </TabPane>

                            <TabPane tabId={8}>
                              <div>
                                <h5>Payment</h5>
                                <p className="text-muted">Choose Payment method and fill in the information below</p>
                              </div>

                              <CardBody>
                                <Nav
                                  tabs
                                  className="nav nav-tabs nav-justified nav-border-top nav-border-top-primary mb-3"
                                >
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({ active: topBorderTab === "1" })}
                                      onClick={() => {
                                        topBordertoggle("1");
                                        validation.setFieldValue("paymentMethod", "bank");
                                      }}
                                    >
                                      <i className=" ri-router-line align-middle me-1"></i> BANK
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({ active: topBorderTab === "2" })}
                                      onClick={() => {
                                        topBordertoggle("2");

                                        validation.setFieldValue("paymentMethod", "simply");
                                        // validation.setFieldValue("accountName", "");
                                        // validation.setFieldValue("accountNumber", "");
                                        // validation.setFieldValue("bank", "");
                                      }}
                                    >
                                      <i className="ri-apps-line me-1 align-middle"></i> SIMPLY
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                  }}
                                >
                                  <TabContent activeTab={topBorderTab} className="text-muted">
                                    <TabPane tabId="1" id="nav-border-justified-home">
                                      <div>
                                        <Row className="gy-3">
                                          <Col sm={12}>
                                            <Label>Select bank</Label>
                                            <Select
                                              md="12"
                                              isClearable
                                              onChange={(e) => validation.setFieldValue("bank", e.value)}
                                              placeholder="Select a bank..."
                                              name="bank"
                                              optional={true}
                                              options={statusOptions}
                                            />
                                          </Col>

                                          <Col sm={12}>
                                            <Label htmlFor="lastName" className="form-label">
                                              Account number
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control rounded-pill"
                                              id="accountNumber"
                                              placeholder="Enter the account number"
                                              defaultValue=""
                                              onChange={validation.handleChange}
                                              value={validation.values.accountNumber}
                                              name={"accountNumber"}
                                            />
                                          </Col>

                                          <TextField
                                            md="12"
                                            mb="mb-3"
                                            value={validation.values.accountName || ""}
                                            onChange={validation.handleChange}
                                            validation={validation}
                                            onBlur={validation.handleBlur}
                                            placeholder="Enter the acccount name"
                                            label="Account name"
                                            name="accountName"
                                          />
                                        </Row>
                                      </div>
                                    </TabPane>

                                    <TabPane tabId="2" id="nav-border-justified-profile"></TabPane>
                                  </TabContent>

                                  <div className="d-flex align-items-start gap-3 mt-4">
                                    <button
                                      type="button"
                                      className="btn btn-light btn-label previestab"
                                      onClick={() => {
                                        toggleVerticalTab(activeVerticalTab - 1);
                                      }}
                                    >
                                      <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to
                                      student Info
                                    </button>
                                    <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                      <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>

                                      {validation.isSubmitting ? "PLEASE WAIT" : "PAY FEE"}
                                    </button>
                                    {/* <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Pay Fee
                                </button> */}
                                  </div>
                                </Form>
                              </CardBody>
                            </TabPane>

                            <TabPane tabId={10}>
                              <div className="text-center pt-4 pb-2">
                                <div className="mb-4">
                                  <lord-icon
                                    src="https://cdn.lordicon.com/lupuorrc.json"
                                    trigger="loop"
                                    colors="primary:#0ab39c,secondary:#405189"
                                    style={{ width: "120px", height: "120px" }}
                                  ></lord-icon>
                                </div>
                                <h5>Your Order is Completed !</h5>
                                <p className="text-muted">
                                  You Will receive an order confirmation email with details of your order.
                                </p>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="fs-14 text-primary mb-0 d-flex align-items-center">
                            <i className="ri-open-arm-line step-icon me-2 h3"></i>
                            Student information
                          </h5>
                          {/* <span className="badge bg-danger rounded-pill">3</span> */}
                        </div>
                        <ul className="list-group mb-3">
                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {studentValidation.values.fullName ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}

                              <h6 className="my-0 mx-3">Student full name</h6>
                              <small className="text-muted mx-3">{studentValidation.values.fullName}</small>
                            </div>
                          </li>

                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {studentValidation.values.studentId ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}
                              <h6 className="my-0  mx-3">Student ID</h6>
                              <small className="text-muted mx-3">{studentValidation.values.studentId}</small>
                            </div>
                          </li>

                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {studentValidation.values.university ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}

                              <h6 className="my-0  mx-3">University</h6>
                              <small className="mx-3 fw-bold  text-success">
                                {studentValidation.values.university}
                              </small>
                            </div>
                          </li>

                          {/* <li className="list-group-item d-flex justify-content-between bg-light">
                            <div className="text-success">
                              {studentValidation.values.studentId ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}
                              <h6 className="my-0  mx-3 ">University</h6>
                              <small className="mx-3 fw-bold">{studentValidation.values.university}</small>
                            </div>
                           </li> */}

                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {studentValidation.values.currentLevel ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}

                              <h6 className="my-0  mx-3">Current level</h6>
                              <small className="text-muted mx-3">{studentValidation.values.currentLevel}</small>
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {studentValidation.values.academicYear ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}
                              <h6 className="my-0  mx-3">Academic Year</h6>
                              <small className="text-muted mx-3">{studentValidation.values.academicYear}</small>
                            </div>
                          </li>
                          {/* 
                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <Col md={12}>
                              <Label htmlFor="cc-name" className="form-label">
                                Amount
                              </Label>
                              <div className="input-group mb-3">
 
                                <Cleave
                                  className="form-control bg-light"
                                  aria-label="Dollar amount (with dot and two decimal places)"
                                  placeholder="0.00"
                                  minLength={10}
                                  readOnly
                                  maxLength={15}
                                  value={validation.values.amount || ""}
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: "thousand",
                                  }}
                                  name="amount"
                                />
                              </div>
                            </Col>
                          </li> */}
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ProccessingLoader alert={alert} setAlert={setAlert} />

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default PaySchoolFees;
