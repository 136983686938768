import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import MsgToast from "../../../Components/Common/MsgToast";

import { APIClient } from "../../../utils/api_helper";

import { profileSuccess } from "../../../store/actions";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Row, UncontrolledCollapse, UncontrolledDropdown } from "reactstrap";

const api = new APIClient();

const SupportTab = ({ user }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState("");

  console.log(user, "PRFOILE_USER");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user?.first_name,
      lastName: user?.last_name,
      // phone: user?.phone,
      // email: user?.email,
      dateOfBirth: user?.date_of_birth,
      city: user?.city,
      address: user?.address,
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter your last name"),
      dateOfBirth: Yup.string().required("Please select the date of birth"),
    }),

    onSubmit: async (values) => {
      console.log(values, "CHANGING_USER");
      setSuccessMsg("");

      setErrorMsg("");
      try {
        const data = await api.update("/users/user/", values);

        console.log(data, "CHANGED_PASSWORD");

        setSuccessMsg(data.message);
        // validation.resetForm();

        dispatch(profileSuccess(data.user));
      } catch (error) {
        // validation.resetForm();

        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            {/* <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0  me-2">Recent Activity</h4>
            </CardHeader> */}
            <CardBody>
              <div className="profile-timeline">
                <div></div>
                <div className="accordion accordion-flush" id="todayExample">
                  <div className="accordion-item border-0">
                    <div className="accordion-header">
                      <button className="accordion-button p-2 shadow-none" type="button" id="headingOne">
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="ri-user-2-fill h4"></i>

                            {/* <img src={avatar2} alt="" className="avatar-xs rounded-circle" /> */}
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="fs-14 mb-1">Phone number</h6>
                            <small className="text-muted">233 24 164 3999</small>
                          </div>
                        </div>
                      </button>
                    </div>
                    <UncontrolledCollapse className="accordion-collapse" toggler="#headingOne" defaultOpen>
                      <div className="accordion-body ms-2 ps-5">
                        we are always available to attend to your enquiries
                      </div>
                    </UncontrolledCollapse>
                  </div>
                  <div className="accordion-item border-0">
                    <div className="accordion-header" id="headingTwo">
                      <Link to="#" className="accordion-button p-2 shadow-none" id="collapseTwo">
                        <div className="d-flex">
                          <div className="flex-shrink-0 avatar-xs">
                            <div className="avatar-title bg-light text-success rounded-circle">M</div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="fs-14 mb-1">Email us</h6>
                            <small className="text-muted">Email us for support</small>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <UncontrolledCollapse toggler="collapseTwo" defaultOpen>
                      <div className="accordion-body ms-2 ps-5">
                        <Row className="g-2">
                          <div className="col-auto">
                            <div className="d-flex border border-dashed p-2 rounded position-relative">
                              <div className="flex-shrink-0">
                                <i className="ri-image-2-line fs-17 text-danger"></i>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h6>
                                  <Link to="#" className="stretched-link">
                                    simplysupport@gmail.com
                                  </Link>
                                </h6>
                                {/* <small>685 KB</small> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="d-flex border border-dashed p-2 rounded position-relative">
                              <div className="flex-shrink-0">
                                <i className="ri-file-zip-line fs-17 text-info"></i>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h6>
                                  <Link to="#" className="stretched-link">
                                    simplyinfo@simply.com
                                  </Link>
                                </h6>
                                {/* <small>8.78 MB</small> */}
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </UncontrolledCollapse>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default SupportTab;
