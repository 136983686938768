import React from "react";
// import Router from "next/router";
// import Link from "next/link";
import { UncontrolledAlert } from "reactstrap";

const MethodImage = {
  PAYPAL: <img src="/static/paypal.png" alt="" />,
  MTN: <img src="/static/mtn-logo.png" alt="" />,
  "MTN Mobile": <img src="/static/mtn-logo.png" alt="" />,

  TIGO: <img src="/static/tigo-logo.png" alt="" />,
  VODAFONE: <img src="/static/vodafone-logo.png" alt="" />,
  simply: <img src="/static/simplytransfer.png" alt="" />,
};

export default function EmptyList({ backgroundColor, text, smallText }) {
  const handleGoToTransaction = () => {
    localStorage.setItem("currentPage", "Send Money");
    // Router.push("/send-money");
  };

  return (
    <section className={`emptylist ${backgroundColor}`}>
      <img src="/static/empty.png" alt="" />

      <UncontrolledAlert color="warning" className="alert-border-left text-center">
        <i className="ri-airplay-line me-3 align-middle fs-16"></i>
        <strong>{text}</strong>{" "}
      </UncontrolledAlert>

      {/* <h4> {text}</h4> */}

      {smallText ? (
        <p>
          It looks like there are no recent transactions to display at the moment. If you have a pending payment, wish
          to make a purchase, or need to send money, now is the perfect time to initiate a transaction!
        </p>
      ) : null}

      {/* <Link href="/send-money"> */}
      {/* <div onClick={handleGoToTransaction} className="action_button">
        <p>
          <a href="">Send Money</a>
        </p>
      </div> */}
      {/* </Link> */}
    </section>
  );
}
