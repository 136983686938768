import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
const Navdata = () => {
  const navigate = useNavigate();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  const [isCases, setIsCases] = useState(false);
  const [isClients, setIsClients] = useState(false);
  const [isDonors, setIsDonors] = useState(false);
  const [IsVolunteers, setIsVolunteers] = useState(false);
  const [IsReports, setIsReports] = useState(false);
  const [IsSettings, setIsSettings] = useState(false);
  const [isAccountSettings, setAccountSettings] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isUsers, setIsUsers] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Cases") {
      setIsCases(false);
    }
    if (iscurrentState !== "Clients") {
      setIsClients(false);
    }
    if (iscurrentState !== "Donors") {
      setIsDonors(false);
    }
    if (iscurrentState !== "Volunteers") {
      setIsVolunteers(false);
    }
    if (iscurrentState !== "Reports") {
      setIsReports(false);
    }
    if (iscurrentState !== "Settings") {
      setIsSettings(false);
    }
    if (iscurrentState !== "AccountSettings") {
      setAccountSettings(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    // if (iscurrentState === "Widgets") {
    //   history.push("/widgets");
    //   document.body.classList.add("twocolumn-panel");
    // }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    navigate,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
    isCases,
    isClients,
    isDonors,
    IsVolunteers,
    IsReports,
    IsSettings,
    isAccountSettings,
    isUsers,
    isOrganization,
  ]);

  const menuItems = [
    {
      id: "home",
      label: "Home",
      // icon: " ri-home-4-line",

      img: true,
      background: "none",
      icon: "/static/icons/home.png",
      link: "/",
    },

    {
      label: "YOUR BUSINESS",
      isHeader: true,
    },
    {
      id: "send-money",
      label: "Send money",
      // icon: "ri-send-plane-line",
      img: true,
      icon: "/static/icons/send.png",

      link: "/send-money",
    },

    {
      id: "transactions",
      label: "Transactions",
      // icon: " ri-numbers-fill",
      link: "/transactions",

      img: true,
      icon: "/static/icons/newtransactions.png",
    },

    {
      id: "Requests",
      label: "My requests",
      // icon: "ri-arrow-left-right-line",
      img: true,
      icon: "/static/icons/money.png",

      link: "/requests",
    },

    {
      id: "pay-fees",
      label: "Pay school fees",
      link: "/pay-school-fees",
      img: true,
      icon: "/static/icons/graduation.png",

      // icon: "material-symbols-outlined",
      parentid: "pay-school-fees",
    },

    {
      id: "pay-bills",
      label: "Pay bills",
      link: "/pay-bills",
      // icon: "ri-layout-grid-line",
      img: true,
      icon: "/static/icons/invoice.png",
      parentid: "settings",
    },
    {
      id: "mobile-recharge",
      label: "Airtime & Data",
      // icon: "ri-smartphone-line",
      img: true,
      icon: "/static/icons/smartphone.png",
      link: "/mobile-recharge",
    },

    {
      label: "TOOLS",
      isHeader: true,
    },

    {
      id: "View statements",
      label: "View statements",
      link: "/statements",
      // icon: "ri-file-list-3-line",
      img: true,
      icon: "/static/icons/statement.png",

      parentid: "settings",
    },
    {
      id: "Loan",
      label: "Loan Center",
      link: "/loan",
      img: true,
      icon: "/static/icons/money.png",
      parentid: "loan",
    },
    {
      id: "Settings",
      label: "Settings",
      link: "/profile-settings",
      // icon: "ri-settings-2-line",
      img: true,
      icon: "/static/icons/settings.png",
      parentid: "settings",
    },
    // {
    //   id: "donors",
    //   label: "Donors",
    //   icon: "ri-user-heart-fill",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsDonors(!isDonors);
    //     setIscurrentState("Donors");
    //     updateIconSidebar(e);
    //   },
    //   parentId: "donors",
    //   stateVariables: isDonors,
    //   subItems: [
    //     {
    //       id: 4,
    //       label: "Leads",
    //       link: "/apps-crm-leads",
    //       parentId: "donors",
    //     },
    //     // {
    //     //   id: 2,
    //     //   label: "Contacts",
    //     //   link: "/donor-contacts",
    //     //   parentid: "donors",
    //     // },
    //     {
    //       id: 3,
    //       label: "Organizations",
    //       link: "/donor-orgs",
    //       parentId: "donors",
    //     },
    //     // {
    //     //   id: 5,
    //     //   label: "Donations",
    //     //   link: "/donor-donations",
    //     //   parentId: "donors",
    //     // },
    //   ],
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
