import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

//Dashboard
import Dashboard from "../apps/dashboard";

//login
import ForgetPasswordPage from "../apps/auth/ForgetPassword";
import ResetUserPassword from "../apps/auth/ResetUserPassword";
import Login from "../apps/auth/Login";
import Logout from "../apps/auth/Logout";
import Register from "../apps/auth/Register";

// Terms of service

// User Profile

// Users
import UsersList from "../apps/users/UsersList";

import SendMoney from "../apps/SendMoney";

import Transactions from "../apps/Transactions";

import ViewTransaction from "../apps/Transactions/Transaction";
import ViewRequest from "../apps/Requests/Request";

import Requests from "../apps/Requests";
import Paybill from "../apps/Paybill";
import Statements from "../apps/Statements";

import Settings from "../apps/Settings";

import Loan from "../apps/Loan";
import AllLoans from "../apps/Loan/LoanListIndex";
import ApplyLoan from "../apps/Loan/ApplyLoan";
import CreateLoan from "../apps/Loan/ApplyLoan/createLoan";
import LoanRepayment from "../apps/Loan/Repayment";

import PaySchoolFees from "../apps/PaySchoolFees";
import ViewFee from "../apps/PaySchoolFees/Fee";

import MobileRecharge from "../apps/MobileRecharge";

import CreateSchoolFees from "../apps/PaySchoolFees/createFees";

import DepositMoney from "../apps/DepositMoney";

import WithdrawMoney from "../apps/Withdrawal";

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },

  // Transaction
  { path: "/Transactions", component: <Transactions /> },
  { path: "/Transactions/:id/", component: <ViewTransaction /> },

  { path: "/users", component: <UsersList /> },
  { path: "/send-money", component: <SendMoney /> },

  // User Profile
  { path: "/requests", component: <Requests /> },
  { path: "/request/:id/", component: <ViewRequest /> },

  // Bills
  { path: "/pay-bills", component: <Paybill /> },

  // Statement
  { path: "/statements", component: <Statements /> },

  { path: "/profile-settings", component: <Settings /> },

  // Loans
  { path: "/Loan", component: <Loan /> },
  { path: "/Loan/list", component: <AllLoans /> },
  { path: "/Loan/apply", component: <ApplyLoan /> },
  { path: "/loan/apply/:type/", component: <CreateLoan /> },
  { path: "/loan/repayment/", component: <LoanRepayment /> },

  // Fees
  { path: "/pay-school-fees", component: <PaySchoolFees /> },
  { path: "/pay-fees", component: <CreateSchoolFees /> },
  { path: "/fee/:id/", component: <ViewFee /> },

  { path: "/mobile-recharge", component: <MobileRecharge /> },

  { path: "/deposit-money", component: <DepositMoney /> },
  { path: "/withdraw-money", component: <WithdrawMoney /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "*", component: <Navigate to="/" replace /> },
];

const publicRoutes = [
  // Public Routes
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/reset-password/:token/:activation_key", component: <ResetUserPassword /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
