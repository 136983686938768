import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postForgotPassword, postResetPassword } from "../../../helpers/backend_helper";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postForgotPassword, { username: user.username });
    if (response) {
      yield put(userForgetPasswordSuccess("Reset link are sended to your mailbox, check there first"));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* resetUser({ payload: { params, data } }) {
  try {
    console.log("RESET PASSWORD", params, data);
    const response = yield call(postResetPassword, params, data);
    if (response) {
      yield put(userResetPasswordSuccess(response.message));
    }
  } catch (error) {
    yield put(userResetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(RESET_PASSWORD, resetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
