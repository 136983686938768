import React, { useMemo, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//redux
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import TableContainer from "../../Components/Common/RequestsTableContainr";

import { Status } from "./TransactionsCol";
import ListLoader from "../../Components/modules/ListLoader";
import CrmFilter from "./CrmFilter";
import { debounce } from "lodash";
import EmptyList from "../../Components/EmptyList";
import RefreshButton from "../../Components/Loaders/RefreshButton";

const AllTransactions = ({ transactionsList, isValidating, count, mutate }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInfoDetails, setIsInfoDetails] = useState(false);

  const updateQueryParam = (value, name) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(name, value);

    // Replace the current URL with the updated query parameters
    // history.replace({ search: newSearchParams.toString() });
    navigate({ search: newSearchParams.toString() });
  };

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const currentUserId = user?.uuid;

  console.log(user, transactionsList, "CCURREENRR");

  const MethodImage = {
    PAYPAL: <img src="/static/paypal.png" alt="" />,
    MTN: <img src="/static/mtn-logo.png" alt="" />,
    mtn: <img src="/static/mtn-logo.png" alt="" />,
    simply: <img src="/static/s-logo-orange.png" alt="" />,

    "MTN Mobile": <img src="/static/mtn-logo.png" alt="" />,

    TIGO: <img src="/static/tigo-logo.png" alt="" />,
    VODAFONE: <img src="/static/vodafone-logo.png" alt="" />,
    // vod: <img src="/static/vodafone-logo.png" alt="" />,
    vod: <img src="/static/icons/vodafone.png" alt="" />,

    // simply: <img src="/static/logo.png" alt="" />,

    // simply: <img src="/static/simplytransfer.png" alt="" />,
  };

  //Column
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Reference",
        accessor: "reference",
        filterable: false,
        Cell: (cell) => {
          const id = cell.row.original.uuid;

          return (
            <div className="color-dark">
              {/* <small className="text-muted"> */}
              <Link to={`/transactions/${id}`} className="fw-medium text-dark">
                {cell.value}
              </Link>
              {/* </small> */}
            </div>
          );
        },
      },
      {
        Header: "P. method",
        Cell: (transaction) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 avatar-xs me-2">
                <div className="simgplyimg rounded-circle fs-13">
                  {MethodImage[transaction.row.original.payment_method]}
                  {/* <span>{transaction.row.original.payment_method}</span> */}
                </div>
              </div>
              {transaction.row.original.payment_method.toUpperCase()}
            </div>
          </>
        ),
      },
      // {
      //   Header: "P. method",
      //   accessor: "payment_method",
      //   filterable: false,
      // },

      {
        Header: "Time",
        Cell: (transaction) => (
          <>
            {/* {handleValidDate(transaction.row.original.date)},{" "} */}
            <small className="text-muted">{transaction.row.original.created_at_arrow}</small>
          </>
        ),
      },
      // {
      //   Header: "AMOUNT",
      //   accessor: "amount",
      //   filterable: false,
      // },
      {
        Header: "Amount",
        Cell: (transaction) => {
          const simply_transaction = (type) => {
            return type === "simply";
          };

          const is_user_payer = (transaction) => {
            return transaction?.payer?.uuid === currentUserId;
          };

          const is_simply_transaction = simply_transaction(transaction.row.original.payment_method);

          const isCurrentUserPayer = is_user_payer(transaction.row.original);

          return (
            <>
              <h6 className={`text amount mb-1  ${isCurrentUserPayer ? "btn-ghost-danger" : "btn-ghost-success"}`}>
                {isCurrentUserPayer && is_simply_transaction ? "-" : "+"}
                <span>&#8373;</span>
                {transaction.row.original.amount}
              </h6>
              <p className="text-muted mb-0">{transaction.row.original.amount1}</p>
            </>
          );
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: (cell) => {
      //     switch (cell.value) {
      //       case "Paid":
      //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
      //       case "Unpaid":
      //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
      //       case "Cancel":
      //         return <span className="badge text-uppercase badge-soft-danger"> {cell.value} </span>;
      //       default:
      //         return <span className="badge text-uppercase badge-soft-info"> {cell.value} </span>;
      //     }
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status status={cellProps?.row?.original?.status} />;
        },
      },
      {
        Header: "Action",
        Cell: (cell) => {
          const id = cell.row.original.uuid;
          return (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`/transactions/${id}`} className="d-inline-block auth-logo">
                  <DropdownItem>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i> View
                  </DropdownItem>
                </Link>

                {/* <DropdownItem href="/apps-transactions-create">
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                </DropdownItem> */}

                {/* <DropdownItem href="/#">
                  <i className="ri-download-2-line align-bottom me-2 text-muted"></i> Download
                </DropdownItem> */}

                <DropdownItem divider />

                {/* <DropdownItem
                  href="#"
                  onClick={() => {
                    const transactionData = cell.row.original;
                    // onClickDelete(transactionData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [transactionsList]
  );

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const handler = useCallback(
    debounce((value) => {
      console.log(value, "SEARCHE_vALUEE");
      updateQueryParam(value, "reference");
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-4 g-3">
        <div className="col-sm-auto ms-auto col-auto">
          <div className="d-flex gap-2">
            {/* <Link to="#" data-bs-toggle="modal" className="btn btn-soft-primary">
              Deposit
            </Link> */}

            <Link to="/requests" data-bs-toggle="modal">
              <button type="button" className="btn btn-secondary btn-label right ms-auto nexttab">
                <i className=" ri-wallet-line label-icon align-middle fs-16 ms-2"></i>
                RECEIVE
              </button>
            </Link>

            <Link to="/send-money" data-bs-toggle="modal">
              <button type="button" className="btn btn-primary btn-label right ms-auto nexttab">
                <i className=" ri-send-plane-fill label-icon align-middle fs-16 ms-2"></i>
                SEND
              </button>
            </Link>
          </div>
        </div>
      </Row>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">All Transactions</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <RefreshButton isValidating={isValidating} mutate={mutate} />

                <div className="search-box">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Search for transaction by reference..."
                    onChange={(e) => {
                      handleFieldSearch(e);
                    }}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <button className="btn btn-soft-success" onClick={toggleInfo}>
                  <i className="ri-equalizer-line align-bottom me-1"></i>
                  Filters
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            columns={columns}
            data={transactionsList || []}
            isGlobalFilter={false}
            isAddUserList={false}
            customPageSize={count}
            className="custom-header-css"
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
            thClass="table-light text-muted"
            customFetchData={() => console.log("yesss")}
            pagination
          />

          {isValidating ? <ListLoader /> : null}

          {!isValidating && !transactionsList?.length ? <EmptyList text="No transactions found yet!" /> : null}
        </CardBody>
      </Card>

      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default AllTransactions;
