import React from "react";
import { CardBody, Row, Col, Card, Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";

import useSWR from "swr";
import { useSelector } from "react-redux";

import { APIClient } from "../../utils/api_helper";

import { useParams } from "react-router-dom";
import StatementSkeleton from "../../Components/Skeletons/StatementSkeleton";
import { Status } from "./TransactionsCol";

const api = new APIClient();

const transactionListFetcher = async (url) => {
  const data = await api.get(url);

  return data;
};

const TransactionDetails = () => {
  const { id } = useParams();

  // Access a specific query parameter
  // const id = query.get("id");

  let { data, error, isValidating } = useSWR(`/transaction/${id || ""}`, transactionListFetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });

  let transaction = data?.data;

  //Print the Invoice
  const printTransaction = () => {
    window.print();
  };

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const currentUserId = user?.uuid;

  console.log(user, "USERRR");

  const is_user_payer = (transaction) => {
    return transaction?.payer?.uuid === currentUserId;
  };

  const isCurrentUserPayer = is_user_payer(transaction);

  console.log(transaction, isCurrentUserPayer, "transactionVVVV");

  console.log(isCurrentUserPayer, "isCurrentUserPayer");

  const transactionItem = isCurrentUserPayer ? transaction?.recipient : transaction?.payer;

  document.title = "Transaction Details";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="transaction Details" linkTo={"/transactions"} pageTitle="transactions" />

        <StatementSkeleton>
          {transaction && (
            // <Row className="justify-content-center">
            <Col xxl={9}>
              <Card id="demo">
                <Row>
                  <Col lg={12}>
                    <CardBody className="p-4">
                      <Row className="g-3">
                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">Transaction Status</p>
                          {/* <span className="badge badge-soft-success fs-11" id="payment-status">
                              {transaction?.status}
                            </span> */}

                          <Status status={transaction?.status} />
                        </Col>
                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">transaction Ref</p>
                          <h5 className="fs-14 mb-0">
                            <span id="transaction-no">{transaction?.reference}</span>
                          </h5>
                        </Col>
                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                          <h5 className="fs-14 mb-0">
                            <span id="transaction-date"> {transaction?.created_at.split("T")[0]}</span>
                            {", "}
                            <small className="text-muted" id="transaction-time">
                              {transaction?.created_at?.split("T")[1]?.split(".")[0]}
                            </small>
                          </h5>
                        </Col>

                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                          {/* <h5 className="fs-14 mb-0 btn-ghost-success">
                          + GHS<span id="total-amount"> {transaction?.amount}</span>
                        </h5> */}

                          <h5
                            className={`text amount mb-1  ${
                              isCurrentUserPayer ? "btn-ghost-danger" : "btn-ghost-success"
                            }`}
                          >
                            {isCurrentUserPayer ? "-" : "+"}
                            <span>&#8373;</span>
                            {transaction?.amount}
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>

                  <Row>
                    <Col lg={6}>
                      <CardBody className="p-4">
                        <div className="mt-3">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">Details:</h6>
                          <p className="text-muted mb-1">
                            Payment Method:{" "}
                            <span className="fw-medium" id="payment-method">
                              {transaction?.payment_method}
                            </span>
                          </p>

                          {transaction?.full_name ? (
                            <p className="text-muted mb-1">
                              Account Holder:{" "}
                              <span className="fw-medium" id="card-holder-name">
                                {transaction?.full_name}
                              </span>
                            </p>
                          ) : null}

                          {transaction?.account_number ? (
                            <p className="text-muted mb-1">
                              Account Number:{" "}
                              <span className="fw-medium" id="card-number">
                                {transaction?.account_number}
                              </span>
                            </p>
                          ) : null}

                          <p className="text-muted">
                            Amount:{" "}
                            <span className="fw-medium" id="">
                              GHS {transaction?.amount}
                            </span>
                          </p>

                          <p className="text-muted">
                            Time:{" "}
                            <span className="fw-medium" id="">
                              {transaction?.created_at_arrow}
                            </span>
                          </p>
                        </div>
                      </CardBody>
                    </Col>

                    {transaction?.payment_method === "simply" ? (
                      <Col lg={6}>
                        <CardBody className="p-4">
                          <div className="mt-3">
                            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              {" "}
                              {isCurrentUserPayer ? "Payment To" : "Payment From"}:
                            </h6>
                            <p className="text-muted mb-1">
                              Full name:{" "}
                              <span className="fw-medium" id="payment-method">
                                {`${transactionItem?.first_name}
                            ${transactionItem?.last_name}`}
                              </span>
                            </p>

                            {transactionItem?.full_name ? (
                              <p className="text-muted mb-1">
                                Account Holder:{" "}
                                <span className="fw-medium" id="card-holder-name">
                                  {transactionItem?.full_name}
                                </span>
                              </p>
                            ) : null}

                            {transactionItem?.account_number ? (
                              <p className="text-muted mb-1">
                                Account Number:{" "}
                                <span className="fw-medium" id="card-number">
                                  {transactionItem?.account_number}
                                </span>
                              </p>
                            ) : null}

                            <p className="text-muted">
                              Phone:{" "}
                              <span className="fw-medium" id="">
                                {transactionItem?.phone}
                              </span>
                            </p>
                          </div>
                        </CardBody>
                      </Col>
                    ) : null}

                    {/* <div className="mt-4">
                    <div className="alert alert-info">
                      <p className="mb-0">
                        <span className="fw-semibold">NOTES:</span>
                        <span id="note">
                          {" "}
                          All accounts are to be paid within 7 days from receipt of transaction. To be paid by cheque or
                          credit card or direct payment online. If account is not paid within 7 days the credits details
                          supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.
                        </span>
                      </p>
                    </div>
                  </div> */}
                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      <Link to="#" onClick={printTransaction} className="btn btn-success">
                        <i className="ri-download-2-line align-bottom me-1"></i> Download
                      </Link>
                      {/* <Link to="#" onClick={printTransaction} className="btn btn-primary">
                      <i className="ri-download-2-line align-bottom me-1"></i> Download
                    </Link> */}
                    </div>
                  </Row>
                </Row>
              </Card>
            </Col>
            // </Row>
          )}
        </StatementSkeleton>
      </Container>
    </div>
  );
};

export default TransactionDetails;
