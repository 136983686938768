export const GET_PROFILE = "GET_PROFILE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const PROFILE_CHANGE_PASSWORD_SUCCESS = "PROFILE_CHANGE_PASSWORD_SUCCESS";
export const PROFILE_CHANGE_PASSWORD_FAIL = "PROFILE_CHANGE_PASSWORD_FAIL";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";

export const GET_CARDS = "GET_CARDS";
export const CARDS_SUCCESS = "CARDS_SUCCESS";
