import React, { useEffect, useMemo, Fragment, useState, useCallback } from "react";
import AsyncSelect from "react-select/async";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Button,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { debounce } from "lodash";

import classnames from "classnames";
//redux
import { useSelector, useDispatch } from "react-redux";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import CustomWebcam from "../Webcam";
import ListLoader from "../../../Components/modules/ListLoader";
import { useLocation } from "react-router-dom";
import EmptyList from "../../../Components/EmptyList";

import { useSearchParams, useNavigate } from "react-router-dom";
import RefreshButton from "../../../Components/Loaders/RefreshButton";
const api = new APIClient();

const RequestModal = ({ validation, modalState }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [isApproved, setIsApproved] = useState(false);
  const [recipient, setRecipient] = useState({});
  const [requestSuccess, setRequestSuccess] = useState("");

  //
  const [captureCamOpen, setCaptureCamOpen] = useState("");

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  console.log(recipient, "RECIPIEEENNNTTTT");

  // Custom Hover Tabs
  const [customHoverTab, setcustomHoverTab] = useState("1");
  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Confirming...",
    message: "Confirming user...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  // events validation
  const userValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recipient: "",
    },

    validationSchema: Yup.object({
      recipient: Yup.string().required("Please enter the recipient"),
    }),

    onSubmit: async (values) => {
      setErrorMsg("");
      setRequestSuccess("");
      setSuccessMsg("");

      setAlert({ status: false });

      setAlert({
        ...alert,
        status: true,
        // message: "Subscription Created Successfully..",
        // title: "Done !",
        // class: "ri-checkbox-circle-fill",
        // textClass: "text-success",
        // back_url: "/login",
      });

      try {
        const { data } = await api.get("/users/user/confirm/", values);

        // setRequestSuccess(data.message);
        customHovertoggle("2");
        setAlert({ ...alert, status: false });
      } catch (error) {
        setAlert({ ...alert, status: false });

        console.log(error, "FUND_MOMO_ERROR");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(userValidation.errors, "ERRORRR__VALL__VALESSS");
  console.log(userValidation.values, "VALL__VALESSS");

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      console.log(inputValue, "INNPUT__vALUE");
      let users = fethUsers(inputValue);
      // const orgList = fethUsers({ search: inputValue }).then(({ contact_obj_list }) => contact_obj_list);
      resolve(users);
    });
  };

  const fethUsers = async (values) => {
    try {
      const { data } = await api.get("/users/search/", { params: { value: values } });

      console.log(data, "USERRR_OBJECTS");
      return data;
    } catch (error) {
      // setAlert({ ...alert, status: false });

      console.log(error, "FUND_MOMO_ERROR");

      let errMsg = error?.response?.data?.message;
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modalState.isOpen}
        role="dialog"
        autoFocus={true}
        centered
        id="topmodal"
        modalClassName="fadeInRight"
        backdrop={"static"}
      >
        <ModalHeader
          toggle={() => {
            modalState.setIsOpen(!modalState.isOpen);
            userValidation.resetForm();
            setcustomHoverTab("1");
          }}
        >
          <h5 className="modal-title" id="addAddressModalLabel">
            Send payment requests
          </h5>
        </ModalHeader>
        <ModalBody>
          <Col xxl={12}>
            <Card>
              <CardBody>
                <p className="text-muted">
                  send <code>payment requests </code> to other simply users
                </p>
              </CardBody>
              <div className="border ">
                <Nav pills className="custom-hover-nav-tabs justify-content-center">
                  <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customHoverTab === "1" })}>
                      <i className="ri-user-fill nav-icon nav-tab-position"></i>
                      <h5 className="nav-titl nav-tab-position m-0">User</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customHoverTab === "2" })}>
                      <i className="ri-file-text-line nav-icon nav-tab-position"></i>
                      <h5 className="nav-titl nav-tab-position m-0">Confirm details</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <CardBody>
                <TabContent activeTab={customHoverTab} className="text-muted">
                  <TabPane tabId="1" id="custom-hover-customere">
                    <div className="table-responsive">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          userValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody>
                          <Input type="hidden" id="id-field" />
                          <Row className="g-3">
                            {/* <Col md={12}>
                              <Label htmlFor="cc-name" className="form-label">
                                User phone number
                              </Label>
                              <div className="input-group mb-3">
                                 <span className="input-group-text">#</span>

                                <Input
                                  type="text"
                                  className="form-control"
                                  aria-label="Dollar amount (with dot and two decimal places)"
                                  name="phone"
                                  placeholder="Enter phone"
                                  onChange={(e) => {
                                    userValidation.setFieldValue("phone", e.target.value);
                                    validation.setFieldValue("phone", e.target.value);
                                  }}
                                  onBlur={userValidation.handleBlur}
                                  value={userValidation.values.phone || ""}
                                  invalid={userValidation.touched.phone && userValidation.errors.phone ? true : false}
                                />
                                {userValidation.touched.phone && userValidation.errors.phone ? (
                                  <FormFeedback type="invalid">
                                    <div>{userValidation.errors.phone}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <small className="text-muted">make sure to verify the number</small>
                            </Col> */}
                            <Col md="12">
                              <div className="mb-3">
                                <Label htmlFor="choices-lead-input" className="form-label">
                                  Search for recipient by First name, Last name, Full name , or Email
                                </Label>
                                <AsyncSelect
                                  className={`${
                                    userValidation.touched.recipient && userValidation.errors.recipient
                                      ? "form-control is-invalid"
                                      : ""
                                  } `}
                                  id="choices-lead-input"
                                  value={recipient}
                                  key={recipient}
                                  name="user_id"
                                  isMulti={false}
                                  isSearchable
                                  isClearable
                                  loadOptions={loadUsersOptions}
                                  getOptionLabel={(option) => (
                                    <span>
                                      <img src={option?.face_id} alt="" height="50" /> {option?.first_name}{" "}
                                      {option?.last_name}
                                    </span>
                                  )}
                                  getOptionValue={(option) => option?.uuid}
                                  defaultOptions={[]}
                                  onBlur={userValidation.handleBlur}
                                  onChange={(e) => {
                                    setRecipient(e);
                                    validation.setFieldValue("recipient", e.uuid);
                                    userValidation.setFieldValue("recipient", e.uuid);
                                  }}
                                />
                                {userValidation.touched.donor && userValidation.errors.donor ? (
                                  <FormFeedback type="invalid">{userValidation.errors.donor}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col md={12}>
                              <Label htmlFor="cc-name" className="form-label">
                                Amount to send
                              </Label>
                              <div className="input-group mb-3">
                                {/* <span className="input-group-text">Ghs</span> */}
                                <span className="input-group-text">
                                  <span>&#8373;</span>
                                </span>

                                <Input
                                  type="number"
                                  className="form-control"
                                  aria-label="Dollar amount (with dot and two decimal places)"
                                  name="amount"
                                  placeholder="Enter amount"
                                  onChange={(e) => {
                                    userValidation.setFieldValue("amount", e.target.value);
                                    validation.setFieldValue("amount", e.target.value);
                                  }}
                                  onBlur={userValidation.handleBlur}
                                  value={userValidation.values.amount || ""}
                                  invalid={userValidation.errors.amount ? true : false}
                                />
                                {userValidation.errors.amount ? (
                                  <FormFeedback type="invalid">
                                    <div>{userValidation.errors.amount}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end">
                            {userValidation.isSubmitting ? (
                              <Button color="info" className="btn-load">
                                <span className="d-flex align-items-center">
                                  <span className="flex-grow-1 me-2">Loading...</span>
                                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                                    {" "}
                                    Loading...{" "}
                                  </Spinner>
                                </span>
                              </Button>
                            ) : (
                              <div className="d-flex align-items-start gap-3 mt-4">
                                <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                  Proceed
                                </button>
                              </div>
                            )}
                          </div>
                        </ModalFooter>
                        {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
                      </Form>
                    </div>

                    <Modal
                      isOpen={alert.status}
                      centered
                      toggle={alert.back_url ? () => null : () => setAlert({ ...alert, status: !alert.status })}
                    >
                      {" "}
                      <ModalBody>
                        <div className="text-center pt-4 pb-2">
                          <div className="mb-4">
                            <div className="avatar-lg mx-auto my-2">
                              <div className={`avatar-title bg-light ${alert.textClass} display-1 rounded-circle`}>
                                <span className="d-flex align-items-center">
                                  <span className={`${alert.class} flex-shrink-0`} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <h5>{alert.title}</h5>
                          <p className="text-muted">{alert.message}</p>
                          <button
                            hidden={!alert.back_url}
                            className="btn btn-soft-primary mt-2"
                            onClick={() => (window.location = alert.back_url)}
                          >
                            Go Back
                          </button>
                        </div>
                      </ModalBody>
                    </Modal>
                  </TabPane>

                  <TabPane tabId="2" id="custom-hover-customere">
                    <div className="">
                      <h4 className="mb-3">
                        <p className="text-center">
                          Send{" "}
                          <span className="btn-outline-success py-1 font-italic"> {validation.values.amount} GHS </span>{" "}
                          payment requests to
                        </p>
                      </h4>
                    </div>

                    <Row>
                      <div className="d-flex justify-content-center align-items-center gap-4">
                        {/* <Col md={5}> */}
                        <img className="rounded-circle avatar-xl" src={recipient?.face_id} width="200" />
                        {/* </Col> */}

                        {/* <Col md={6}> */}
                        <h4 className="mb-3 d-flex   justify-content-center">
                          {recipient?.first_name} {recipient?.last_name}
                        </h4>
                        {/* </Col> */}
                      </div>
                    </Row>

                    <div className="table-responsive">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody>
                          <Input type="hidden" id="id-field" />
                          <Row className="g-3">
                            <div className="d-flex align-items-center gap-2">
                              <Button className="btn-soft-success" onClick={() => setCaptureCamOpen(!captureCamOpen)}>
                                capture photo
                              </Button>

                              <Label className="form-label">Capture your photo to continue</Label>
                            </div>
                          </Row>

                          {validation.values.requester_cam ? (
                            <Col className="border border-dashed border-2 border-primary  d-flex justify-content-center mt-4">
                              <img className="avatar-xl text-center" src={validation.values.requester_cam} alt="" />
                            </Col>
                          ) : null}
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-space-around">
                            <button type="button" className="btn btn-soft-dark" onClick={() => setcustomHoverTab("1")}>
                              Go Back
                            </button>

                            {!validation.isSubmitting && !isApproved && (
                              <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                <i className="ri-send-plane-2-fill label-icon align-middle fs-16 ms-2"></i>
                                Confirm
                              </button>
                            )}

                            {validation.isSubmitting && (
                              <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                                <span className="d-flex align-items-center">
                                  <span className="flex-grow-1 me-2">Loading...</span>
                                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                                    {" "}
                                    Loading...{" "}
                                  </Spinner>
                                </span>
                              </Button>
                            )}
                          </div>
                        </ModalFooter>
                        {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
                      </Form>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                onClick={() => {
                  modalState.setIsOpen(!modalState.isOpen);
                  userValidation.resetForm();
                  setcustomHoverTab("1");
                }}
                type="button"
                className="btn btn-light"
              >
                Close
              </button>
            </div>
          </ModalFooter>

          <Modal isOpen={captureCamOpen} role="dialog" autoFocus={true} centered id="addAddressModal">
            <ModalHeader
              toggle={() => {
                // setModal(!modal);
                setCaptureCamOpen(false);
              }}
            >
              <CustomWebcam validation={validation} />
            </ModalHeader>
          </Modal>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RequestModal;
