import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { logoutUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    // localStorage.clear();
    return navigate("/login");
  }

  return <>logging out, please wait</>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
