// import React from "react";
// import { ProgressBar, Spinner } from "react-bootstrap";

// const ProgressStatus = ({ progress, match_status }) => {
//   const variant =
//     match_status === "success"
//       ? "success"
//       : match_status === "pending"
//       ? "warning"
//       : match_status === "fail"
//       ? "danger"
//       : "";

//   return (
//     <React.Fragment>
//       {match_status === "pending" && (
//         <Spinner animation="border" variant="dark" className="" />
//       )}

//       <div className="float-start me-3">{progress}%</div>

//       <div className="mt-2">
//         <ProgressBar
//           now={progress}
//           style={{ height: "5px" }}
//           striped
//           variant={variant}
//         />
//       </div>
//     </React.Fragment>
//   );
// };
// export { ProgressStatus };
import React from "react";
import { Spinner } from "reactstrap";

const getCurrentBgColor = (width) => {
  let bg = "danger";

  if (width > 50 && width < 70) {
    bg = "bg-danger";
  } else if (width > 70 && width <= 80) {
    bg = "bg-info";
  } else if (width > 80) {
    bg = "bg-success";
  }

  return bg;
};

const ProgressStatus = ({ progress, match_status, verification_progress, verification_status }) => {
  const variant =
    match_status === "success"
      ? "success"
      : match_status === "pending"
      ? "warning"
      : match_status === "fail"
      ? "danger"
      : "";

  let width = verification_progress;

  let currentBg = getCurrentBgColor(width);

  return (
    <React.Fragment>
      {verification_status === "pending" ? (
        <div className="d-flex justify-content-center align-items-center mb-3">
          <Spinner size="sm" type="grow" role="status"></Spinner>
          Verification in progress, please wait
        </div>
      ) : (
        ""
      )}

      <div className="progress animated-progess rounded-bottom rounded-0" style={{ height: "12px" }}>
        <div
          className={"progress-bar rounded-0 " + currentBg}
          role="progressbar"
          style={{ width: width + "%" }}
          aria-valuenow="30"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {width} %
        </div>
      </div>
    </React.Fragment>
  );
};
export { ProgressStatus };
