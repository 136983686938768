import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD_FLAGS,
} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  reset: false,
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        resetSuccessMsg: null,
        resetError: null,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetStatus: true,
        reset: true,
        resetStatusMsg: action.payload,
      };
      break;
    case RESET_PASSWORD_ERROR:
      state = { ...state, reset: true, resetStatus: false, resetError: action.payload };
      break;

    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload };
      break;
    case RESET_FORGET_PASSWORD_FLAGS:
      state = { resetStatus: false, reset: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
