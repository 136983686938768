import DashboardAccount from "../../Components/DashboardAccount";
import TransactionsList from "../../Components/TransactionsList";
import TransactionsListMobile from "../../Components/TransactionsListMobile";
import Loader from "../../Components/modules/Loader";

import React, { useState } from "react";

import useSWR from "swr";
import EmptyList from "../../Components/EmptyList";
// import Router from "next/router";
import ListLoader from "../../Components/modules/ListLoader";
import { APIClient } from "../../utils/api_helper";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const api = new APIClient();

const fetcher = async (...args) => {
  const data = await api.get("/users/user/wallet/");

  return data;
};

export default function Home({ user }) {
  let {
    data: data1,
    error,
    isValidating,
    mutate: mutateWallet,
  } = useSWR(`/users/user/wallet/`, fetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });

  let {
    data: transactionsList,
    error: transactionList_error,
    isValidating: isTransactionListValidating,
    mutate: mutateTransactionList,
  } = useSWR(`/transaction/`, transactionListFetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleGoToTransaction = () => {
    localStorage.setItem("currentPage", "Transaction");
    // Router.push("/transactions");
  };

  async function transactionListFetcher(...args) {
    setIsLoading(true);

    const data = await api.get("/transaction/");
    setIsLoading(false);

    return data.data;
  }

  console.log(isLoading, "ISSSS__LOADING");

  transactionsList = transactionsList?.slice(0, 5);

  const mutateAll = (...mutateFunctions) => {
    mutateTransactionList();
    mutateWallet();

    mutateFunctions.forEach((mutateFunction) => mutateFunction());
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="Transactions" pageTitle="Crypto" /> */}

          {/* <main className="home__page"> */}
          {/* {data1?.balance ? ( */}
          <>
            <DashboardAccount walletBalance={data1?.balance} isValidating={isValidating} mutate={mutateAll} />

            <React.Fragment>
              <div className="transactions__homeheader">
                <div className="transactions__homeheader--container">
                  <span>Recent Transactions</span>
                  <div className="transactions__homeheader--left">
                    <p onClick={handleGoToTransaction}>View All</p>
                  </div>
                </div>

                {isLoading ? <ListLoader /> : null}
              </div>

              {!transactionsList?.length ? <EmptyList /> : null}

              {transactionsList?.length ? (
                <React.Fragment>
                  {" "}
                  <TransactionsList
                    isValidating={isTransactionListValidating}
                    transactionsList={transactionsList}
                    user={user}
                    isFromHomePage
                    backgroundColor="backgroundColor"
                  />
                  <TransactionsListMobile
                    isValidating={isTransactionListValidating}
                    transactionsList={transactionsList}
                    isFromHomePage
                  />{" "}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          </>
          {/* ) : ( */}
          {/* <Loader /> */}
          {/* )} */}
          {/* </main> */}
        </Container>
      </div>
    </React.Fragment>
  );
}
