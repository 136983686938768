import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllFees from "./AllFees";
import Widgets from "./Widgets";
import StatementSkeleton from "../../Components/Skeletons/StatementSkeleton";
import useSWR from "swr";

import { APIClient } from "../../utils/api_helper";
const api = new APIClient();

const request_stats_fetcher = async (url) => {
  const { data } = await api.get(url);

  return data;
};

const Transactions = () => {
  let {
    data: stats,
    error: request_error,
    isValidating,
    mutate,
  } = useSWR(`/school/fees/aggregates/semester/`, request_stats_fetcher, {});

  const mutateAll = () => {
    mutate();
  };

  document.title = "Pay school fees | SimplyPay";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pay School Fees" pageTitle="Pay bills" linkTo={"/pay-bills"} />

          <StatementSkeleton>{stats && <Widgets data={stats} />}</StatementSkeleton>

          <AllFees isValidating={isValidating} mutate={mutateAll} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
