import React from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

const StepLineChart = ({ stats, dataColors }) => {
  var chartStepLineColors = getChartColorsArray(dataColors);
  var option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Payouts", "Payins", "Requests"],
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      data: stats?.days,

      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(133, 141, 152, 0.1)",
        },
      },
    },
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
    color: chartStepLineColors,
    series: [
      {
        name: "Payouts",
        type: "line",
        step: "start",
        data: stats?.payout,
      },
      {
        name: "Payins",
        type: "line",
        step: "middle",
        data: stats?.payin,
      },
      {
        name: "Requests",
        type: "line",
        step: "end",
        data: stats?.payment_requests,
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={option} />
    </React.Fragment>
  );
};

export default StepLineChart;
