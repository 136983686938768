import React, { useState } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../../utils/api_helper";
import TextField from "../../../Components/Common/Fields/TextField";
import SelectField from "../../../Components/Common/Fields/SelectField";
import { useDispatch } from "react-redux";
import { getCards } from "../../../store/actions";

const api = new APIClient();

const AddCardModal = ({ openAddCardModal, setOpenAddCardModal }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding card, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const validateCard = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountNumber: "",
    },

    validationSchema: Yup.object({
      accountNumber: Yup.string().required("Please enter the account number"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Validating...",
          message: "Validating, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/wallets/card/validate/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        setSuccessMsg(data.message);
        setErrorMsg("");

        setAlert({ ...alert, status: false });
        topBordertoggle("2");
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  console.log(validateCard.errors, "VALIDATION__ERRORS");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountNumber: "",
      type: "",
      description: "",
      otp_code: "",
    },

    validationSchema: Yup.object({
      accountNumber: Yup.string().required("Please enter the account number"),
      type: Yup.string().required("Please select your card type"),
      description: Yup.string().required("Please enter a description"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Proccessing...",
          message: "Adding card, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/wallets/card/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        dispatch(getCards());

        setSuccessMsg(data.message);
        setErrorMsg("");

        validation.resetForm();

        // toggleModal();

        setAlert({ ...alert, status: false });

        setOpenAddCardModal(false);

        // setTimeout(() => {
        //  }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  const productState = [
    { label: "MTN", value: "mtn" },
    { label: "VODAFONE", value: "vod" },
    { label: "AT", value: "atl" },
  ];

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal
          id="topmodal"
          isOpen={openAddCardModal}
          toggle={() => {
            setOpenAddCardModal(false);
            topBordertoggle("1");
          }}
        >
          <ModalHeader
            className="modal-title"
            id="myModalLabel"
            toggle={() => {
              setOpenAddCardModal(false);
              topBordertoggle("1");
            }}
          ></ModalHeader>
          <ModalBody className="">
            <Col lg={12} xl={12} xxl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xxl={12} lg={12} xl={12}>
                      <CardBody>
                        {/* <Nav tabs className="nav nav-tabs nav-justified nav-border-top nav-border-top-primary mb-3">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({ active: topBorderTab === "1" })}
                              onClick={() => {
                                topBordertoggle("1");
                              }}
                            >
                              <i className=" ri-router-line align-middle me-1"></i> ACCOUNT
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({ active: topBorderTab === "2" })}
                              onClick={() => {}}
                            >
                              <i className="ri-apps-line me-1 align-middle"></i> DETAILS
                            </NavLink>
                          </NavItem>
                        </Nav> */}

                        <TabContent activeTab={topBorderTab} className="text-muted">
                          <TabPane tabId="1" id="nav-border">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                validateCard.handleSubmit();
                              }}
                            >
                              {/* <ModalBody> */}
                              <Row>
                                <SelectField
                                  md="6"
                                  mb="mb-3"
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="select..."
                                  label="Select type"
                                  name="type"
                                  optional={true}
                                  defaultValue={productState.find((c) => c.value === validation.values.type)}
                                  key={validation.type}
                                  options={productState}
                                />

                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validateCard.values.accountNumber || ""}
                                  onChange={(e) => {
                                    console.log(e.target.value, "valuess");

                                    const value = e.target.value;

                                    validateCard.setFieldValue("accountNumber", value);
                                    validation.setFieldValue("accountNumber", value);
                                  }}
                                  validation={validateCard}
                                  onBlur={validateCard.handleBlur}
                                  placeholder="Enter the acccount number"
                                  label="Account number"
                                  name="accountNumber"
                                />

                                <div className="mb-3">
                                  <TextField
                                    md="12"
                                    mb="mb-3"
                                    value={validation.values.description || ""}
                                    onChange={validation.handleChange}
                                    validation={validation}
                                    onBlur={validation.handleBlur}
                                    label="Description"
                                    name="description"
                                    placeholder="eg. my mtn wallet"
                                  />
                                </div>
                              </Row>
                              {/* </ModalBody> */}
                              <ModalFooter>
                                <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                  {validateCard.isSubmitting ? "PLEASE WAIT" : "CONFIRM"}
                                </button>
                              </ModalFooter>
                            </Form>

                            {successMsg ? (
                              <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" />
                            ) : null}

                            <ProccessingLoader alert={alert} setAlert={setAlert} />
                          </TabPane>

                          <TabPane tabId="2" id="nav-border-justified-profile">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                              }}
                            >
                              {/* <ModalBody> */}
                              <Row>
                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.otp_code || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="Enter the otp code"
                                  label="Enter the otp code"
                                  name="otp_code"
                                />
                              </Row>
                              {/* </ModalBody> */}
                              <ModalFooter>
                                <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                  PROCEED
                                </button>
                              </ModalFooter>
                            </Form>
                          </TabPane>

                          <TabPane tabId="3" id="nav-border-justified-messages"></TabPane>
                        </TabContent>
                      </CardBody>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
        </Modal>
        {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

        <ProccessingLoader alert={alert} setAlert={setAlert} />
      </React.Fragment>
    </React.Fragment>
  );
};

export default AddCardModal;
