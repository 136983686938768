import React, { useState } from "react";

import { Card, CardBody, Col, Row, Form, Label, Input, Button, Spinner, Table } from "reactstrap";
import "swiper/css";
import "swiper/css/pagination";

import TextField from "../../../Components/Common/Fields/TextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import MsgToast from "../../../Components/Common/MsgToast";

import { APIClient } from "../../../utils/api_helper";
import { handleValidDate } from "../../../Utility";
import DateField from "../../../Components/Common/Fields/DateField";
import { profileSuccess } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const api = new APIClient();

const ManageProfileTab = ({ user }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState("");

  console.log(user, "PRFOILE_USER");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user?.first_name,
      lastName: user?.last_name,
      // phone: user?.phone,
      // email: user?.email,
      dateOfBirth: user?.date_of_birth,
      city: user?.city,
      address: user?.address,
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter your last name"),
      dateOfBirth: Yup.string().required("Please select the date of birth"),
    }),

    onSubmit: async (values) => {
      console.log(values, "CHANGING_USER");
      setSuccessMsg("");

      setErrorMsg("");
      try {
        const data = await api.update("/users/user/", values);

        console.log(data, "CHANGED_PASSWORD");

        setSuccessMsg(data.message);
        // validation.resetForm();

        dispatch(profileSuccess(data.user));
      } catch (error) {
        // validation.resetForm();

        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h5 className="card-title mb-3">Profile information</h5>
          <div className="table-responsive">
            <Table className="table-borderless mb-0">
              <tbody>
                <tr>
                  <th className="ps-0" scope="row">
                    Full Name :
                  </th>
                  <td className="text-muted">
                    {user?.first_name} {user?.last_name}
                  </td>
                </tr>
                <tr>
                  <th className="ps-0" scope="row">
                    Mobile :
                  </th>
                  <td className="text-muted">+(233) {user?.phone}</td>
                </tr>
                <tr>
                  <th className="ps-0" scope="row">
                    E-mail :
                  </th>
                  <td className="text-muted">{user?.email}</td>
                </tr>
                <tr>
                  <th className="ps-0" scope="row">
                    Location :
                  </th>
                  <td className="text-muted">Accra, Ghana</td>
                </tr>
                <tr>
                  <th className="ps-0" scope="row">
                    Joined since
                  </th>
                  <td className="text-muted">{user?.date_joined}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Col md={12}>
        <Card>
          <CardBody>
            <h5 className="card-title mb-4">Update profile</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();

                return false;
              }}
            >
              <Row>
                <TextField
                  md="4"
                  mb="mb-3"
                  value={validation.values.firstName || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your first name"
                  label="First name"
                  name="firstName"
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  value={validation.values.lastName || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your last name"
                  label="Last name"
                  name="lastName"
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  value={user?.phone || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your phone number"
                  label="Phone number"
                  name="phone"
                  readOnly
                />

                <DateField
                  mb="mb-3"
                  md="4"
                  value={validation.values.dateOfBirth || ""}
                  // onChange={validation.handleChange}
                  onChange={(e) => {
                    const date = handleValidDate(e);
                    console.log(date, "DATE__FIELDDDL");

                    validation.setFieldValue("dateOfBirth", date);
                  }}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  label="Date of birth"
                  name="dateOfBirth"
                  // errorMessage={error && error.errors}
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  value={validation?.values?.address || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your residential address"
                  label="Residential address"
                  name="address"
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  value={user?.email || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your email number"
                  label="Email address"
                  name="email"
                  readOnly
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  value={validation.values.city || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your city"
                  label="city"
                  name="city"
                />

                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="countryInput" className="form-label">
                      Country
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="countryInput"
                      placeholder="Country"
                      defaultValue="Ghana"
                    />
                  </div>
                </Col>

                <Col md={6} lg={6}>
                  {validation.isSubmitting ? (
                    <div className="text-end">
                      <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">Loading...</span>
                          <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        </span>
                      </Button>
                    </div>
                  ) : (
                    <div className="text-end">
                      <button type="submit" className="btn btn-success">
                        Update
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default ManageProfileTab;
