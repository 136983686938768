import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Form, Button, Spinner } from "reactstrap";
import "swiper/css";
import "swiper/css/pagination";
import TextField from "../../../Components/Common/Fields/TextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import MsgToast from "../../../Components/Common/MsgToast";

import { APIClient } from "../../../utils/api_helper";

const api = new APIClient();

const ChangePasswordTab = ({ data }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object({
      current_password: Yup.string().required("Please enter current password"),
      new_password: Yup.string().required("Please enter your new password"),
      confirm_password: Yup.string().required("Please enter your confirm password"),
    }),

    onSubmit: async (values) => {
      console.log(values, "CHANGING__PASSWORD");
      setSuccessMsg("");

      setErrorMsg("");
      try {
        const data = await api.post("/account/change-password/", values);

        console.log(data, "CHANGED_PASSWORD");

        setSuccessMsg(data.message);
        validation.resetForm();
      } catch (error) {
        validation.resetForm();

        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  return (
    <React.Fragment>
      <Col md={12}>
        <Card>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();

                return false;
              }}
            >
              <Row className="g-2">
                <TextField
                  md="4"
                  mb="mb-3"
                  value={validation.values.current_password || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your current password"
                  label="Current password"
                  name="current_password"
                  type="password"
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  value={validation.values.new_password || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Enter your new password"
                  label="New password"
                  name="new_password"
                  type="password"
                />

                <TextField
                  md="4"
                  mb="mb-3"
                  type="password"
                  value={validation.values.confirm_password || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="Confirm your new password"
                  label="Confirm password"
                  name="confirm_password"
                />

                <div className="d-flex hstack gap-2 justify-content-space-between">
                  <Col md={6} lg={6}>
                    <div className="mb-3">
                      <Link to="#" className="link-primary text-decoration-underline">
                        Forgot Password ?
                      </Link>
                    </div>
                  </Col>

                  <Col md={6} lg={6}>
                    {validation.isSubmitting ? (
                      <div className="text-end">
                        <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">Loading...</span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          </span>
                        </Button>
                      </div>
                    ) : (
                      <div className="text-end">
                        <button type="submit" className="btn btn-success">
                          Change Password
                        </button>
                      </div>
                    )}
                  </Col>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default ChangePasswordTab;
