import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed, registrationError } from "./actions";
import { postRegister } from "../../../helpers/backend_helper";
import { apiError } from "../login/actions";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postRegister, {
        email: user.email,
        password: user.password,
        username: user.first_name,
      });

      console.log("RESPONSE: ", response);

      // yield sessionStorage.setItem("authUser", JSON.stringify(response));

      if (response.error) {
        if (response.status === 400) {
          yield put(registerUserFailed(response));
        }

        if (response.status === 500) {
          yield put(registrationError(response));
        }
      } else {
        if (response.status === 201) {
          yield sessionStorage.setItem("authUser", JSON.stringify(response));

          yield put(registerUserSuccessful(response));
        }
      }
    }
  } catch (error) {
    yield put(registrationError(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
