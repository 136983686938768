import React from "react";

const FromCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const ToCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const DetailsCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const TransactionID = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const TypeCol = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Status = ({ status }) => {
  console.log(status, "TSSSSSSSSSS");

  return (
    <React.Fragment>
      {status === "pending" ? (
        <span className="badge badge-soft-warning fs-11">
          {" "}
          <i className="ri-time-line align-bottom"></i> verification {status}
        </span>
      ) : status === "success" ? (
        <span className="badge badge-soft-success fs-11">
          {" "}
          <i className="ri-checkbox-circle-line align-bottom"></i> account verifed
        </span>
      ) : status === "fail" ? (
        <span className="badge badge-soft-danger fs-11">
          {" "}
          <i className="ri-close-circle-line align-bottom"></i> verification failed
        </span>
      ) : null}
    </React.Fragment>
  );
};

export { FromCol, ToCol, DetailsCol, TransactionID, TypeCol, Status };
