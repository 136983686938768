import React, { useState } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../../utils/api_helper";
import TextField from "../../../Components/Common/Fields/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../../../store/actions";

const api = new APIClient();

const OtpModal = ({ openOtpModal, setOpenOtpModal, reference }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [topBorderTab, settopBorderTab] = useState("1");

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding card, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const validateCharge = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: "send_otp",
      reference: reference,
      otp: "",
    },

    validationSchema: Yup.object({
      reference: Yup.string().required("Please enter the account number"),
      otp: Yup.string().required("Please enter the otp code sent"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Validating...",
          message: "Validating, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/transaction/charge/verify/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        const charge_response = data?.charge_response?.data;

        // if (charge_response.status === "pay_offline") {
        setOpenOtpModal(false);
        // }

        setSuccessMsg(charge_response.display_text);

        setAlert({ ...alert, status: false });
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  console.log(validateCharge.values, "VALIDATE_CHART33");

  console.log(validateCharge.errors, "VALIDATION__ERRORS");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
      type: "",
      description: "",
      otp_code: "",
    },

    validationSchema: Yup.object({
      otp: Yup.string().required("Please enter the account number"),
      type: Yup.string().required("Please select your card type"),
      description: Yup.string().required("Please enter a description"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Proccessing...",
          message: "Adding card, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/wallets/card/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        dispatch(getCards());

        setSuccessMsg(data.message);
        setErrorMsg("");

        validation.resetForm();

        // toggleModal();

        setAlert({ ...alert, status: false });

        setOpenOtpModal(false);

        // setTimeout(() => {
        //  }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal
          id="topmodal"
          isOpen={openOtpModal}
          toggle={() => {
            // setOpenOtpModal(false);
          }}
        >
          <ModalHeader
            className="modal-title"
            id="myModalLabel"
            toggle={() => {
              setOpenOtpModal(false);
            }}
          ></ModalHeader>
          <ModalBody className="">
            <Col lg={12} xl={12} xxl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xxl={12} lg={12} xl={12}>
                      <CardBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validateCharge.handleSubmit();
                          }}
                        >
                          {/* <ModalBody> */}
                          <Row>
                            <TextField
                              md="6"
                              mb="mb-3"
                              value={validateCharge.values.otp || ""}
                              onChange={(e) => {
                                console.log(e.target.value, "valuess");

                                const value = e.target.value;

                                validateCharge.setFieldValue("otp", value);
                              }}
                              validation={validateCharge}
                              onBlur={validateCharge.handleBlur}
                              placeholder="Enter the acccount number"
                              label="Account number"
                              name="otp"
                            />
                          </Row>
                          {/* </ModalBody> */}
                          <ModalFooter>
                            <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                              <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                              {validateCharge.isSubmitting ? "PLEASE WAIT" : "CONFIRM"}
                            </button>
                          </ModalFooter>
                        </Form>

                        {successMsg ? (
                          <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" />
                        ) : null}

                        <ProccessingLoader alert={alert} setAlert={setAlert} />
                      </CardBody>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
          </ModalBody>
        </Modal>

        <ProccessingLoader alert={alert} setAlert={setAlert} />
      </React.Fragment>
    </React.Fragment>
  );
};

export default OtpModal;
