import React, { useEffect, useState } from "react";

import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from "reactstrap";
import Countdown from "react-countdown";

import { Link } from "react-router-dom";
import classnames from "classnames";

import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";
import ProccessingLoader from "../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../utils/api_helper";
import TextField from "../../Components/Common/Fields/TextField";
import SelectField from "../../Components/Common/Fields/SelectField";
import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../../store/actions";

const api = new APIClient();

const LoginOtpModal = ({ openOtpModal, setOpenOtpModal, email, urlPath }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });
  const togglemodal = () => {
    setModal(!modal);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp_code: "",
      email,
    },

    validationSchema: Yup.object({
      otp_code: Yup.string().required("Please enter the otp code"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "validating otp...",
          message: "validating otp, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");

        const data = await api.post(`/account/otp/${urlPath}/`, values);
        console.log(data, "REQUEST__STATS__DATAA");
        const access_token = data.access_token;

        localStorage.setItem("access_token", access_token);

        setSuccessMsg(data.message);
        setErrorMsg("");

        validation.resetForm();

        // toggleModal();

        setAlert({ ...alert, status: false });

        // setOpenOtpModal(false);

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  console.log(validation.values, "VALIDATINFF");

  console.log(validation.errors, "VAL_ERRORR");

  const handleResentOtp = async () => {
    try {
      setAlert({
        status: true,
        title: "Resending otp...",
        message: "Resending otp, please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      setErrorMsg("");
      setSuccessMsg("");
      const data = await api.update("/account/otp/login/", { email });
      console.log(data, "REQUEST__STATS__DATAA");

      setSuccessMsg(data.message);
      setErrorMsg("");

      setAlert({ ...alert, status: false });
    } catch (error) {
      console.log(error, "LOGIN");
      setErrorMsg("");

      let errMsg = error?.response?.data?.message;

      console.log(errMsg, "ERRRRRRRRRRRR");
      errMsg = errMsg || "internal server occured";

      setErrorMsg(errMsg);

      console.log(errMsg, "ER__MERSG");

      setAlert({
        status: true,
        title: "Sorry!",
        message: errMsg,
        //class: "ri-information-line",
        class: "ri-error-warning-line ri-3xl",
        textClass: "text-primary",
        //back_url: "/accounts/plans",
        //// redirect user to the customer portal
        dismissable: true,
      });
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div>
          <Button
            color="light"
            onClick={() => {
              handleResentOtp();
            }}
          >
            <small className="d-flex justify-content-center">Resend otp</small>
          </Button>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <span>
          <small className="d-flex justify-content-center">
            Resend otp code in {hours}:{minutes}:{seconds}
          </small>
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal
          isOpen={openOtpModal}
          toggle={() => {
            // setOpenOtpModal(false);
            // topBordertoggle("1");
          }}
        >
          <ModalHeader
            className="modal-title"
            id="myModalLabel"
            toggle={() => {
              setOpenOtpModal(false);
              // topBordertoggle("1");
            }}
          ></ModalHeader>
          <ModalBody className="">
            <Col lg={12} xl={12} xxl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xxl={12} lg={12} xl={12}>
                      <CardBody>
                        <ModalHeader>Enter the OTP code sent to your phone</ModalHeader>

                        <TabContent activeTab={topBorderTab} className="text-muted">
                          <TabPane tabId="1" id="nav-border-justified-profile">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                              }}
                            >
                              <Row>
                                <TextField
                                  md="12"
                                  mb="mb-3"
                                  value={validation.values.otp_code || ""}
                                  onChange={(e) => {
                                    console.log(e.target.value, "valuess");

                                    const value = e.target.value;

                                    validation.setFieldValue("otp_code", value);
                                  }}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="Enter the otp code code"
                                  // label="Enter OTP_CODEotp_code code"
                                  name="otp_code"
                                />
                              </Row>

                              <ModalFooter>
                                <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                  SUBMIT
                                </button>
                              </ModalFooter>
                              {successMsg ? (
                                <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" />
                              ) : null}
                            </Form>

                            <small className="d-flex justify-content-center">
                              <Countdown date={Date.now() + 300000} renderer={renderer} />
                            </small>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
        </Modal>

        <ProccessingLoader alert={alert} setAlert={setAlert} />
      </React.Fragment>
    </React.Fragment>
  );
};

export default LoginOtpModal;
