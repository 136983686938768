import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllLoans from "./AllLoans";
import Widgets from "./Widgets";

import useSWR from "swr";

import StatementSkeleton from "../../Components/Skeletons/StatementSkeleton";

import { APIClient } from "../../utils/api_helper";
import { useSearchParams } from "react-router-dom";

const api = new APIClient();

const loanListFetcher = async (url) => {
  const data = await api.get(url);

  return data;
};

export default function Loan({ user }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = new URLSearchParams(searchParams);

  // Access a specific query parameter
  // const type = query.get("type") || "";

  console.log(query, "QUERY__PARAMSSS");

  //
  // let {
  //   data: loanList,
  //   error: transactionList_error,
  //   isValidating: isLoanListValidating,
  // } = useSWR(`/loan/`, loanListFetcher, {
  //   revalidateOnFocus: false, // revalidate when the page gains focus
  //   revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  // });

  document.title = "Loan list | SimplyPay";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Loan list" pageTitle="Loan list" linkTo="/loan" />

          {/* <SettingsSkeleton> */}
          {/* {stats && ( */}
          <React.Fragment>
            {/* <StatementSkeleton>{stats && <Widgets data={stats} />}</StatementSkeleton> */}

            <AllLoans />
          </React.Fragment>
          {/* )} */}
          {/* </SettingsSkeleton> */}
        </Container>
      </div>
    </React.Fragment>
  );
}
